import { useEffect, useLayoutEffect, useState, useTransition } from "react"
import { useNavigate } from "react-router-dom"
import AxioRequests from "../../functions/axiosRequests"
import ROUTES from "../../util/routes"

const useMyOrder = ({userID, isAdmin}) => {
    const [loading, setLoading] = useState({
        orderSummary: true,
        transactionList: true,
        initial: true,
    })

    const [isPending, startTransition] = useTransition()
    const navigate = useNavigate();

    const [transactionList, setTransactionList] = useState([]);
    const [orderSummary, setOrderSummary] = useState({
        productList: [],
        totalDiscount: { percent: 0, amount: 0 },
        totalTaxes: [{ taxName: '', percent: 0, amount: 0 }],
        totalAmount: 0,
        finalAmount: 0,
        userGST: '',
        userName: '',
        userRole: '',
        orderDate: '',
        orderID: '',
    });
    const [totalPendencies, setTotalPendencies] = useState(0);

    const [memberData, setMemberData] = useState({
        userDetails: {
            userName: '',
            userMobile: '',
            userAddress: '',
            userEmail: '',
            businessSign: '',
            userType: '',
            GST: '',
        },
        billsList: []
    })

    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])


    useEffect(() => {
        if(memberData.billsList?.length > 0){
            let tempTotalPendencies = 0;
            memberData.billsList.forEach(bill => tempTotalPendencies += bill.remainingAmount)

            startTransition(() => {
                setTotalPendencies(tempTotalPendencies);
            })
        }
    }, [memberData])


    useEffect(() => {
        if(loading.initial){
            const finalRoute = isAdmin ? ROUTES.CommonBillRoute : `${ROUTES.CommonBillRoute}/${userID}`;
            handleGetRequest(finalRoute)
        }
    }, [loading.initial, userID])

    async function handleGetRequest(route) {
        const response = await AxioRequests.HandleGetRequest(route);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(prev => ({ ...prev, initial: false }))
                setMemberData(prev => ({...prev, ...response?.data}))
            })

        }
    }

    async function handleFetchTransactionList(orderID) {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.CommonTransactionRoute}/${orderID}`);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(prev => ({ ...prev, transactionList: false }))
                setTransactionList(response?.data)
            })

        }
    }

    async function fetchOrderSummary(orderID) {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.CommonOrderSummaryRoute}/${orderID}`);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(prev => ({ ...prev, transactionList: false }))
                setOrderSummary(prev => ({...prev, ...response?.data}));
            })

        }
    }

    async function handlePayment(amount, orderID) {
        const response = await AxioRequests.HandlePostRequest({
            route: `${ROUTES.CommonTransactionRoute}/${orderID}`,
            type: 'post',
            toastDescription: 'Payment has done successfully',
            data: { orderID: orderID, paidAmount: amount }
        })

        if (response?.status === 200) {
            setLoading(prev => ({...prev, initial: true}))
        }
        return response
    }

    async function handleOrderDelete(orderID) {
        const response = await AxioRequests.HandlePostRequest({
            route: `${ROUTES.CommonBillRoute}/${orderID}`,
            type: 'delete',
            toastDescription: 'Order has deleted successfully',
            data: { orderID: orderID }
        })

        if (response?.status === 200) {
            window.location.reload();
        }
    }

    return {
        memberData,
        transactionList,
        handlePayment,
        handleFetchTransactionList,
        isPending,
        loading,
        totalPendencies,
        fetchOrderSummary,
        orderSummary,
        handleOrderDelete,
    }

}

export default useMyOrder;