import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import ExportButton from '../../../functions/exportToExcel';
import useLendingPartners from '../../../hooks/lending/useLendingPartners';
import usePagination from '../../../hooks/usePagination';
import ModalWrapper from '../../modalWrapper';
import LendingPartnerForm from './partnerForm';


const LendingPartner = () => {
    const {userList, handleUserDelete} = useLendingPartners();

    const [filteredUserList, setFilteredUserList] = useState(userList);
    const {PaginationComponent, currentList} = usePagination(filteredUserList);

    const [searchFilter, setSearchFilter] = useState('')
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState({
        show: false,
        title: 'Add New User',
        id: '',
    })

    useEffect(() => {
        if (userList?.length > 0) {
            let tempList = userList;
            if (searchFilter !== '') {
                tempList = tempList.filter(user => user.name.toLowerCase().includes(searchFilter.toLowerCase()))
            }

            setFilteredUserList(tempList)
        }
    }, [userList, searchFilter])


    function handleModalForm(showDetail, modalTitle, userID) {
        setModalData({ show: showDetail, title: modalTitle, id: userID }); 
        setModalToggle(true)
    }


    return (
        <>
            <div className='d-flex justify-content-between gap-2 align-items-center my-2 mt-md-4'>
                <h2 className='text-light'> Partners Details</h2>
                <Button type='button' className='w-auto' onClick={() => handleModalForm(false, 'Add New Partner', '')}>Add New Partner</Button>
            </div>
            <div className='my-2 my-md-4 d-flex gap-2 align-items-center justify-content-between'>
                <input  style={{width:'auto'}} type='text' name='searchUser' id='searchUser' placeholder='Search User by Name' className='form-control rounded-2' value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
                <ExportButton data={userList} fileName='partners-list' className='max-content' />
            </div>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        {/* <th>Email</th> */}
                        <th>Mobile</th>
                        <th className='text-center'>Interact</th>
                    </tr>
                </thead>
                <tbody>
                    {currentList?.length > 0 ? currentList.map((user, index) => (
                        <tr key={user.userID}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            {/* <td>{user.email}</td> */}
                            <td>{user.mobile}</td>
                            <td>
                                <div className="d-flex gap-2 w-100 justify-content-center align-items-center">
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="button-tooltip" {...props}>
                                                View Details
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="primary" onClick={() => handleModalForm(true, "View User's Details", user._id)}>
                                            <FaEye />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="Edit Details" {...props}>
                                                Edit Details
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="warning" onClick={() =>  handleModalForm(false, "Edit User's Details", user._id)}>
                                            <FaEdit />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="Delete-User" {...props}>
                                                Delete User
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="danger" type='button' onClick={() => handleUserDelete(user._id)}>
                                            <FaTrashAlt />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </td>
                        </tr>
                    )) : <tr><td>No partners found</td></tr>}
                </tbody>
            </Table>
            <PaginationComponent />
            {modalToggle ? 
                <ModalWrapper title={modalData.title} toggle={modalToggle} setToggle={setModalToggle} >
                    <LendingPartnerForm id={modalData.id} showDetails={modalData.show} handleConfirmation={() => {
                        window.location.reload();
                        setModalToggle(!modalToggle);
                    }} />
                </ModalWrapper >
            : <></>}
        </>
    );
};

export default LendingPartner;
