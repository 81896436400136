import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import { FireToast } from '../../../context/toastContext.js';
import useProduct from '../../../hooks/billing/useProduct.js';
import VALIDATION from '../../../validation/product.js';


const ProductForm = ({ id, handleConfirmation, editMode = false }) => {
    const { defaultValues, handleSubmit } = useProduct(id, editMode);

    return (
        <Formik
            initialValues={defaultValues}
            enableReinitialize
            validationSchema={VALIDATION.productFormValidation}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const response = await handleSubmit(values);
                    console.log(response)
                    response?.status === 200 && handleConfirmation();
                } catch (err) {
                    console.log(err);
                    FireToast({ title: 'Error', subTitle: err.response?.data?.message });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, touched, dirty, isValid, values, setFieldValue }) => {
                // console.log(errors)
                return (
                    <Form className='row rounded border shadow-lg p-4 m-2'>
                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productName">Product Name</FormLabel>
                            <Field
                                name="productName"
                                type="text"
                                placeholder='Product Name'
                                as={FormControl}
                                isInvalid={!!(errors.productName && touched.productName)}
                            />
                            <ErrorMessage name="productName" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productSKU">Product SKU</FormLabel>
                            <Field
                                name="productSKU"
                                type="text"
                                placeholder='RJ-123'
                                as={FormControl}
                                isInvalid={!!(errors.productSKU && touched.productSKU)}
                            />
                            <ErrorMessage name="productSKU" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 mb-2'>
                            <FormLabel htmlFor="productDescription">Product Description</FormLabel>
                            <Field
                                name="productDescription"
                                as="textarea"
                                placeholder='Detail about Product'
                                rows={4}
                                className={`form-control ${errors.productDescription && touched.productDescription ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="productDescription" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productSize">Product Size</FormLabel>
                            <Field
                                name="productSize"
                                placeholder='Product Size'
                                type="text"
                                as={FormControl}
                                isInvalid={!!(errors.productSize && touched.productSize)}
                            />
                            <ErrorMessage name="productSize" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productImage">Product Image</FormLabel>
                            <input
                                type='file'
                                name='productImage'
                                id='productImage'
                                as={FormControl}
                                className='form-control'
                                onChange={e => setFieldValue('productImage', e.target.files[0])}
                            />
                            <ErrorMessage name="productImage" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-4 mb-2'>
                            <FormLabel htmlFor="productPrice">Buying Price</FormLabel>
                            <Field
                                name="productPrice"
                                placeholder='0'
                                type="number"
                                as={FormControl}
                                step={0.1}
                                isInvalid={!!(errors.productPrice && touched.productPrice)}
                            />
                            <ErrorMessage name="productPrice" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-4 mb-2'>
                            <FormLabel htmlFor="sellingPrice">Selling Price</FormLabel>
                            <Field
                                name="sellingPrice"
                                placeholder='0'
                                type="number"
                                as={FormControl}
                                step={0.1}
                                isInvalid={!!(errors.sellingPrice && touched.sellingPrice)}
                            />
                            <ErrorMessage name="sellingPrice" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-4 mb-2'>
                            <FormLabel htmlFor="productQuantity">Product Quantity</FormLabel>
                            <Field
                                name="productQuantity"
                                type="number"
                                placeholder='0'
                                as={FormControl}
                                isInvalid={!!(errors.productQuantity && touched.productQuantity)}
                            />
                            <ErrorMessage name="productQuantity" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-4 mb-2'>
                            <div className='d-flex align-items-center justify-content-between cursor-pointer w-100 h-100'>
                                <FormLabel htmlFor="isAvailable" className='mb-0'>Product is Available ?</FormLabel>
                                <input type="checkbox" name="isAvailable" id="isAvailable" checked={values?.isAvailable} onChange={(e) => setFieldValue('isAvailable', e.target.checked)} />
                            </div>
                            <ErrorMessage name="isAvailable" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <div className='col-12 my-2 my-md-4'>
                            <Button type="submit" variant="primary" className='mx-auto d-block' disabled={!(dirty && isValid)}>
                                {editMode ? 'Update Product Details' : 'Create New Product'}
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default ProductForm;
