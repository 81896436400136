import React from 'react'
import { useAuthContext } from '../../../context/authContext'
import ExportButton from '../../../functions/exportToExcel'
import useBilling from '../../../hooks/lending/useBilling'
import LendingOrderList from './transaction'


const LendingBills = ({baseUserID = ''}) => {
    const { userID } = useAuthContext();
    const hasAdminUser = baseUserID === '';
    const finalUser = hasAdminUser ? userID : baseUserID;
    const { memberData, handlePayment } = useBilling({userID: finalUser, isAdmin: hasAdminUser});
   
    return (
        <>
            {!hasAdminUser && 
                <div className='fw-bold d-flex flex-column gap-2 align-items-center justify-content-center border rounded border-light p-2 px-md-4 my-2 my-md-4 text-light'>
                    <div className='fs-2 fw-bold'>{memberData?.userDetails?.userName}</div>
                    <div><small className='fw-normal'>Email: </small> {memberData?.userDetails?.userEmail} |  <small className='fw-normal'>Mobile: </small> +91 {memberData?.userDetails?.userMobile} </div>
                    <div><small className='fw-normal'>Address: </small>{memberData?.userDetails?.userAddress}</div>
                </div>
            }

            <div className='d-flex justify-content-between gap-2 align-items-center mt-2 mt-md-4'>
                {hasAdminUser && <ExportButton data={memberData.billsList} fileName='bill-list' className='max-content' /> }
            </div>
            <LendingOrderList data={memberData.billsList} handlePayment={handlePayment} />
        </>
    )
}

export default LendingBills