import React, { useEffect } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { FaMinus, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { useAuthContext } from '../../../context/authContext'
import useLendingCheckout from '../../../hooks/lending/useCheckout'

const LendingCheckout = () => {
    const { userData } = useAuthContext()
    const { orderData, handleDeleteProduct, handleCart,  handlePlaceOrder } = useLendingCheckout();

    return (
        <>
            <div className='d-flex justify-content-between gap-2 align-items-center my-2 my-md-4'>
                <Button type='button' className='w-auto' onClick={handlePlaceOrder}>Place Order</Button>
            </div>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Product Name</th>
                        <th className='text-center'>Quantity</th>
                        {/* <th className='text-center'>Product Price</th> */}
                        <th className='text-center'>Lending Price (INR)</th>
                        {/* <th className='text-center'>Total Price (INR)</th> */}
                        <th className='text-center'>Total Lending Price (INR)</th>
                        <th className='text-center'>Lending Percentage</th>
                        <th className='text-center'>Lending Tenure</th>
                        <th className='text-center'>Interact</th>
                    </tr>
                </thead>
                <tbody>
                    {orderData?.products?.length > 0 ? (
                        orderData?.products?.map((item, index) => (
                            <tr key={`${item.productID}-${index + 1}`} className='no-blur'>
                                <td>{index + 1}</td>
                                <td>{item.productName}</td>
                                <td className='text-center'>{item.productQuantity}</td>
                                {/* <td className='text-center'>₹ {item.productPrice}</td> */}
                                <td className='text-center'>₹ {item.lendingAmount}</td>
                                {/* <td className='text-center'>₹ {item.totalPrice}</td> */}
                                <td className='text-center'>₹ {item.totalLendingPrice}</td>
                                <td className='text-center'>{item.lendingPercentage} %</td>
                                <td className='text-center'>{item.lendingMonths} {item.lendingMonths > 1 ? 'Months' : 'Month'}</td> 
                                <td>
                                    <div className="d-flex gap-2 align-items-center justify-content-center">
                                        <OverlayTrigger
                                            placement="top"
                                            className='h-auto'
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => (
                                                <Tooltip id="Remove-Quantity" {...props}>
                                                    Remove Quantity
                                                </Tooltip>
                                            )}
                                        >
                                            <Button size='sm' type='button' onClick={() => handleCart(item.productID, 'remove')}>
                                                <FaMinus />
                                            </Button>
                                        </OverlayTrigger>
                                        {item.productQuantity}
                                        <OverlayTrigger
                                            placement="top"
                                            className='h-auto'
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => (
                                                <Tooltip id="Add-Quantity" {...props}>
                                                    Add Quantity
                                                </Tooltip>
                                            )}
                                        >
                                            <Button size='sm' type='button' onClick={() => {
                                                if (item.productQuantity < item.totalQuantity || userData.switchedUser?.userRole === 'Seller') {
                                                    handleCart(item.productID, 'add')
                                                }
                                            }}>
                                                <FaPlus />
                                            </Button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="top"
                                            className='h-auto'
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => (
                                                <Tooltip id="Delete-User" {...props}>
                                                    Delete User
                                                </Tooltip>
                                            )}
                                        >
                                            <Button variant="danger" size='sm' type='button' onClick={() => handleDeleteProduct(item.productID)}>
                                                <FaTrashAlt />
                                            </Button>
                                        </OverlayTrigger>

                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : <tr><td>No Product in your cart</td></tr>}

                </tbody>
                <tfoot>
                    <tr className='fw-bold'>
                        <td colSpan={'2'}>Total</td>
                        <td colSpan={'1'} className='text-center'>{orderData?.productQuanity}</td>
                        {/* <td colSpan={'1'} className='text-center'>₹ {orderData?.productPrice}</td> */}
                        <td colSpan={'1'} className='text-center'>₹ {orderData?.lendingAmount}</td>
                        {/* <td colSpan={'1'} className='text-center'>₹ {orderData?.totalProductPrice}</td> */}
                        <td colSpan={'1'} className='text-center'>₹ {orderData?.totalLendingAmount}</td>
                        <td colSpan={'1'} className='text-center'>{orderData?.totalLendingPercent} %</td>
                        <td colSpan={'2'}></td>
                    </tr>
                </tfoot>
            </Table >
        </>
    )
}

export default LendingCheckout