import React, { useEffect, useState, startTransition } from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const itemsPerPageOption = [
    {
        id: crypto.randomUUID(),
        value: 10,
        label: '10 per page', 
    },
    {
        id: crypto.randomUUID(),
        value: 25,
        label: '25 per page', 
    },
    {
        id: crypto.randomUUID(),
        value: 50,
        label: '50 per page', 
    },
    {
        id: crypto.randomUUID(),
        value: 100,
        label: '100 per page', 
    },
]

const usePagination = (list) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(sessionStorage.getItem('valuePerPage') || itemsPerPageOption[0].value);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentList = list.length > 0 ? list.slice(indexOfFirstItem, indexOfLastItem) : [];


    useEffect(() => {
        const pageNumbers = Math.ceil(list.length / itemsPerPage);

        startTransition(() => {
            setTotalPages(pageNumbers)
        })
    }, [itemsPerPage, list])


    function handleValuePerPage(value){
        startTransition(() => {
            setItemsPerPage(value);
            setCurrentPage(1)
            sessionStorage.setItem('valuePerPage', value)
        })
    }

    // Handle page change
    function handlePageChange(pageNumber) {
        startTransition(() => {
            setCurrentPage(pageNumber);
        })
    };

    const ItemsPerPageDropdown = () => {
        return (
            <select name='valuePerPage' value={itemsPerPage} id='valuePerPage' className='form-control w-auto' onChange={(e) => handleValuePerPage(e.target.value)} >
                {itemsPerPageOption?.map(item => (
                    <option key={item.id} value={item.value}>{item.label}</option>
                ))}
            </select>
        )
    }

    const ArrowBtns = () => {
        return (
            <div className='d-flex gap-2'>
                <Button type='button' disabled={currentPage <= 1} onClick={() => {
                    if(currentPage > 1) handlePageChange(currentPage - 1)
                }}>
                    <FaArrowLeft />
                </Button>
                <div className='d-flex align-self-ceter justify-self-center bg-light text-body-background p-2 px-3 rounded'>{currentPage}</div>
                <Button type='button' disabled={currentPage >= totalPages} onClick={() => {
                    if(currentPage < totalPages) handlePageChange(currentPage + 1)
                }}>
                    <FaArrowRight />
                </Button>
            </div>
        )
    }

    const PaginationComponent = () => {
        return (
            <div className='d-flex gap-2 justify-content-end'>
                <ArrowBtns />
                <ItemsPerPageDropdown />
            </div>
        )
    }



  return {
    ItemsPerPageDropdown,
    currentList,
    ArrowBtns,
    PaginationComponent,
  }
}

export default usePagination