import { useEffect, useLayoutEffect, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/loader";
import { useAuthContext } from "../../context/authContext";
import AxioRequests from "../../functions/axiosRequests";
import ROUTES from "../../util/routes";

const useReportPanel = () => {
    const [loading, setLoading] = useState(true);
    const [isPending, startTransition] = useTransition();
    const { userID } = useAuthContext();
    const navigate = useNavigate();

    const [productList, setProductList] = useState([]);
    const [reportList, setReportList] = useState([]);


    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])

    useEffect(() => {
        loading && handleGetRequest()
    }, [loading])

    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(ROUTES.getProductList);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(false)
                setProductList(response.data)
            })

        }
    }      

    async function handleReport(productID) {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.CommonProductReportRoute}/${productID}`);
        if (response?.status === 200) {
            startTransition(() => {
                setReportList(response.data)
            })

        }
    }

    if (isPending) {
        return <Loader message='Waiting for server response' />;
    }

    return {
        productList,
        reportList,
        handleReport,
    }
}

export default useReportPanel;