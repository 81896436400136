import React from 'react';
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';

const filterFields = (data, fieldsToRemove) => {
  return fieldsToRemove?.length > 0 ? data.map(item => {
    const newItem = { ...item };
    fieldsToRemove?.forEach(field => delete newItem[field]);
    return newItem;
  }) : data;
};


// Example component
const ExportButton = ({ data, fieldsToRemove = [], fileName = 'exported-data', className = '' }) => {

    // Export function
    function handleExport() {
        const filteredData = filterFields(data, fieldsToRemove);
        
        // Create a new workbook and add a worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        
        // Write the workbook to a file
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

  return (
    <Button type='button' onClick={handleExport} className={className}>Export to Excel</Button>
  );
};

export default ExportButton;
