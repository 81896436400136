import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEdit, FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useAuthContext } from "../../../context/authContext";
import useProductList from "../../../hooks/lending/useProductList";
import ModalWrapper from "../../modalWrapper";
import LendingProductForm from "../lending/productForm";
import ROUTES from "../../../util/routes";
import ExportButton from "../../../functions/exportToExcel";

const LendingProductList = () => {
  const { userData } = useAuthContext();
  const {
    productList,
    selectedProductOwner,
    handleProductDeletion,
    handleCart,
    handleGoToCart,
  } = useProductList();

  const [filteredProductList, setFilteredProductList] = useState(productList);
  const [searchFilter, setSearchFilter] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const [modalData, setModalData] = useState({
    isEdit: false,
    title: "Add New Product",
    id: "",
  });

  const [productToggle, setProductToggle] = useState(false);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    if (productList?.length > 0) {
      let tempList = productList;
      if (searchFilter !== "") {
        tempList = tempList.filter((product) =>
          product.productName.toLowerCase().includes(searchFilter.toLowerCase())
        );
      }

      setFilteredProductList(tempList);
    }
  }, [productList, searchFilter]);

  return (
    <>
      <div className="d-flex justify-content-between gap-2 align-items-center my-2 my-md-4">
        <div className="d-flex gap-2">
          <Button
            type="button"
            className="w-auto"
            onClick={() => {
              setModalData({ isEdit: false, title: "Add New Product", id: "" });
              setModalToggle(true);
            }}
          >
            Add New Product
          </Button>
          <ExportButton
            data={productList}
            fileName="product-list"
            className="max-content"
          />
          {userData?.borrowingCart?.items?.length > 0 && (
            <Button type="button" className="w-auto" onClick={handleGoToCart}>
              Go To Cart
            </Button>
          )}
        </div>
      </div>
      <div className="my-2 my-md-4">
        <input
          type="text"
          name="searchUser"
          id="searchUser"
          placeholder="Search Product by Name"
          className="form-control rounded-2"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <div className="row">
        {filteredProductList?.length > 0 ? (
          filteredProductList.map((product, index) => (
            <ProductCard
              key={product.productID}
              product={product}
              index={index}
              userData={userData}
              setModalData={setModalData}
              setModalToggle={setModalToggle}
              handleProductDeletion={handleProductDeletion}
              setProductToggle={setProductToggle}
              setProductData={setProductData}
              handleCart={handleCart}
              selectedProductOwner={selectedProductOwner}
            />
          ))
        ) : (
          <div className="text-light">No Product Found</div>
        )}
      </div>
      {modalToggle === true ? (
        <ModalWrapper
          title={modalData.title}
          toggle={modalToggle}
          setToggle={setModalToggle}
        >
          <LendingProductForm
            id={modalData.id}
            editMode={modalData.isEdit}
            handleConfirmation={() => {
              setModalToggle(!modalToggle);
              window.location.reload();
            }}
          />
        </ModalWrapper>
      ) : (
        <></>
      )}
      {productToggle === true ? (
        <ModalWrapper
          title={"Product Details"}
          toggle={productToggle}
          setToggle={setProductToggle}
          hasBackground={false}
          size={"sm"}
        >
          {productData !== null && (
            <div className="text-light p-2">
              <div className="fw-bold fs-4">{productData.productName}</div>
              <hr className="my-0" />
              <small className="fw-bold">Product Desciption</small>
              <hr className="my-0" />
              <div className="fs-6 mb-2">{productData.productDescription}</div>
              <hr className="my-0" />
              <small className="fw-bold">Lending Terms</small>
              <hr className="my-0" />
              <div className="fs-6 mb-2">{productData.lendingTerms}</div>
              <hr className="my-0" />
              <div className="row my-2">
                <div className="col-6 mb-1 text-truncate">
                  <OverlayTrigger
                    placement="top"
                    className="h-auto"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip id="Lending-Amount" {...props}>
                        Lending Amount
                      </Tooltip>
                    )}
                  >
                    <div>Lending Amount</div>
                  </OverlayTrigger>
                </div>
                <div className="col-6 mb-1 text-truncate">
                  : {productData.lendingAmount}
                </div>
                <div className="col-6 mb-1 text-truncate">
                  <OverlayTrigger
                    placement="top"
                    className="h-auto"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip id="Lending-Percent" {...props}>
                        Lending Percent
                      </Tooltip>
                    )}
                  >
                    <div>Lending Percent</div>
                  </OverlayTrigger>
                </div>
                <div className="col-6 mb-1 text-truncate">
                  : {productData.lendingPercent}%
                </div>
                <div className="col-6 mb-1 text-truncate">
                  <OverlayTrigger
                    placement="top"
                    className="h-auto"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip id="Lending-Tenure" {...props}>
                        Lending Tenure
                      </Tooltip>
                    )}
                  >
                    <div>Lending Tenure</div>
                  </OverlayTrigger>
                </div>
                <div className="col-6 mb-1 text-truncate">
                  : {productData.lendingTenure}{" "}
                  {productData.lendingTenure > 0 ? "Months" : "Month"}
                </div>
                <div className="col-6 mb-1 text-truncate">Product Size</div>
                <div className="col-6 mb-1 text-truncate">
                  : {productData.productSize}
                </div>
                <div className="col-6 mb-1 text-truncate">Product Weight</div>
                <div className="col-6 mb-1 text-truncate">
                  :{" "}
                  {productData.productWeight !== "NA"
                    ? `${productData.productWeight} GM`
                    : productData.productWeight}
                </div>
              </div>
              <div className="w-100 d-flex my-2">
                <div className="w-50">Product Quantity</div>
                <div className="w-50">
                  <small className="fs-6">
                    : {productData.productQuantity}{" "}
                    {productData.productQuantity > 1 ? "PCs" : "PC"}
                  </small>
                </div>
              </div>
            </div>
          )}
        </ModalWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default LendingProductList;

const ProductCard = ({
  product,
  index,
  userData,
  setModalData,
  setModalToggle,
  handleProductDeletion,
  setProductToggle,
  setProductData,
  handleCart,
}) => {
  const hasCartItems = userData?.borrowingCart?.items?.length > 0;
  const hasIteminCart = hasCartItems
    ? userData.borrowingCart.items.some(
        (item) => item.productID === product.productID
      )
    : false;
  const selectedItem =
    hasIteminCart &&
    userData.borrowingCart.items.find(
      (item) => item.productID === product.productID
    );
  const selectedItemQuantity = hasIteminCart ? selectedItem.productQuantity : 0;
  const productImage =
    product.productImage === "" || product.productImage === null
      ? `https://picsum.photos/200/300?random=${index + 1}`
      : `${ROUTES.IMAGE_URL}/${product.productImage}`;

  return (
    <div key={product.productID} className="col-12 col-md-4 col-lg-3 mb-3">
      <div className="product-wrapper rounded p-3 position-relative">
        <div className="position-absolute w-100 top-0 start-0 end-0 d-flex justify-content-between">
          <div className="bg-light text-body-background fw-bold fs-6 max-height rounded px-2">
            Available Quantities: {product.productQuantity}
          </div>
          <div className="mt-2 me-2 d-flex gap-2 align-items-center w-25">
            <OverlayTrigger
              placement="top"
              className="h-auto"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  Edit Product
                </Tooltip>
              )}
            >
              <Button
                variant="warning"
                size="sm"
                onClick={() => {
                  setModalData({
                    isEdit: true,
                    title: "Edit Product's Details",
                    id: product.productID,
                  });
                  setModalToggle(true);
                }}
              >
                <FaEdit />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              className="h-auto"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="Delete-User" {...props}>
                  Delete User
                </Tooltip>
              )}
            >
              <Button
                variant="danger"
                type="button"
                size="sm"
                className="fw-bold"
                onClick={() => handleProductDeletion(product.productID)}
              >
                <FaTrashAlt />
              </Button>
            </OverlayTrigger>
          </div>
        </div>

        <img src={productImage} alt={`${product.productName}s Image`} />
        <div className="fs-4 truncate-2-lines">{product.productName}</div>
        <div className="truncate-3-lines text-light-grey position-relative">
          {product.productDescription}
          <div
            style={{
              backgroundColor: "white",
              color: "black",
              padding: "0.5rem",
              borderRadius: "15px",
              fontWeight: 500,
            }}
            className="position-absolute end-0 bottom-0 px-2 cursor-pointer"
            onClick={() => {
              setProductToggle(!true);
              setProductData(product);
            }}
          >
            Read More...
          </div>
        </div>
        <div className="row">
          <div className="w-25">
            <small>Borrower:</small>
          </div>
          <div className="w-75 text-end">
            <b>{product.productOwnerName}</b>
          </div>
          <div className="w-50 d-flex justify-content-start">
            <small className="">
              Product Price: <b>₹{product.productPrice}</b>
            </small>
          </div>
          <div className="w-50 d-flex justify-content-end">
            <small className="">
              Lended Amount: <b>₹{product.lendingAmount}</b>
            </small>
          </div>
        </div>
        {product.productQuantity <= 0 || (hasCartItems && !hasIteminCart) ? (
          <Button type="button" disabled>
            Out of Stock
          </Button>
        ) : hasIteminCart ? (
          <div className="cart-adding-button-wrapper">
            <Button
              size="sm"
              type="button"
              onClick={() => handleCart(product.productID, "remove")}
            >
              <FaMinus />
            </Button>
            {selectedItemQuantity}
            <Button
              size="sm"
              type="button"
              onClick={() => {
                if (selectedItemQuantity + 1 <= product.productQuantity) {
                  handleCart(product.productID, "add");
                }
              }}
            >
              <FaPlus />
            </Button>
          </div>
        ) : (
          <Button
            type="button"
            onClick={() => handleCart(product.productID, "add")}
          >
            Add to Cart
          </Button>
        )}
      </div>
    </div>
  );
};
