import { useEffect, useLayoutEffect, useState, useTransition } from "react";
import Loader from "../../component/loader";
import AxioRequests from "../../functions/axiosRequests";
import ROUTES from "../../util/routes";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";

const useShoppingPanel = () => {
    const [loading, setLoading] = useState(true);
    const [isPending, startTransition] = useTransition();
    const { userID, userData, setUserData } = useAuthContext();
    const navigate = useNavigate();

    const [productList, setProductList] = useState([]);
    const [tempPrductCartQty, setTempPrductCartQty] = useState({});

    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])

    useEffect(() => {
        loading && handleGetRequest()
    }, [loading])

    useEffect(() => {
        const switchedUserID = userData?.switchedUser?.userID;
        const prevCart = [...(userData?.cart || [])];
        const userCart = prevCart.find(cart => cart.userID === switchedUserID); 
        if (userCart) {
            let tempCartQty = {};
            const updatedItems = [...userCart?.items || []];

            updatedItems?.forEach(item => {
                tempCartQty[item.id] = item.quantity
            })
            
            startTransition(() => {
                setTempPrductCartQty(tempCartQty)
            });
        }
    }, [userData?.cart, userData?.switchedUser])

    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(ROUTES.getProductList);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(false)
                setProductList(response.data)
            })

        }
    }

    async function handleProductDeletion(id) {
        const response = await AxioRequests.HandlePostRequest({
            route: `${ROUTES.CommonProductRoute}/${id}`,
            type: 'delete',
            toastDescription: 'Product has been deleted successfully',
            data: { userID: id }
        });
        if (response.status === 200) {
            startTransition(() => {
                setLoading(true)
            })
        }
    }

    async function handleCart(id, type) {
        const switchedUserID = userData?.switchedUser?.userID;

        
        // Make a shallow copy of userData.cart
        const prevCart = [...(userData?.cart || [])];
    
        // Find the user's cart
        const userCartIndex = prevCart.findIndex(cart => cart.userID === switchedUserID);
    
        if (userCartIndex === -1) {
            // User cart not found, create a new cart entry
            const newUserCart = {
                userID: switchedUserID,
                items: [{ id: id, quantity: 1 }]
            };
    
            startTransition(() => {
                setUserData(prev => ({ ...prev, cart: [...prev.cart, newUserCart] }));
            });
    
            return;
        }
    
        // User cart found, update the items
        const userCart = prevCart[userCartIndex];
        const updatedItems = [...userCart.items];
    
        if (type === 'add') {
            // Check if the item already exists in the cart
            const itemIndex = updatedItems.findIndex(item => item.id === id);
    
            if (itemIndex !== -1) {
                // Item exists, increase quantity
                updatedItems[itemIndex].quantity += 1;
            } else {
                // Item does not exist, add it
                updatedItems.push({ id: id, quantity: 1 });
            }
        } else if (type === 'remove') {
            // Update the items array by decreasing quantity or removing item
            const filteredItems = updatedItems
                .map(item => {
                    if (item.id === id) {
                        if (item.quantity > 1) {
                            return { ...item, quantity: item.quantity - 1 };
                        }
                        return null;
                    }
                    return item;
                })
                .filter(item => item !== null);
    
            // Update the cart state
            startTransition(() => {
                setUserData(prev => {
                    const updatedCart = prev.cart.map(cart =>
                        cart.userID === switchedUserID ? { ...cart, items: filteredItems } : cart
                    );
                    return { ...prev, cart: updatedCart };
                });
            });
    
            return;
        }
    
        // Update the cart state for 'add' type
        startTransition(() => {
            setUserData(prev => {
                const updatedCart = prev.cart.map(cart =>
                    cart.userID === switchedUserID ? { ...cart, items: updatedItems } : cart
                );
                return { ...prev, cart: updatedCart };
            });
        });
    }

    async function handleGoToCart() {
        const response = await AxioRequests.HandlePostRequest({
            route: ROUTES.postCartRoute,
            type: 'post',
            toastDescription: 'Product has added to the cart successfully',
            data: {cart: userData.cart }
        });
        if (response?.status === 200) {
            navigate('../checkout')
        }
    }

    function handleTempCartQty(productID, quantity){
        setTempPrductCartQty(prev => ({...prev, [productID]: quantity}));
    }

    if (isPending) {
        return <Loader message='Waiting for server response' />;
    }

    return {
        productList,
        handleProductDeletion,
        startTransition,
        handleCart,
        handleGoToCart,
        tempPrductCartQty,
        handleTempCartQty,
    }
}

export default useShoppingPanel;