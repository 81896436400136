import { useEffect, useState, useLayoutEffect, useTransition } from "react"
import { useAuthContext } from "../../context/authContext"
import AxioRequests from "../../functions/axiosRequests"
import ROUTES from "../../util/routes"
import { useNavigate } from "react-router-dom"
import Loader from "../../component/loader"
import { FireToast } from "../../context/toastContext"

const useLendingCheckout = () => {
    const [loading, setLoading] = useState(true)
    const [isPending, startTransition] = useTransition()
    const { userID, userData, setUserData } = useAuthContext()
    const navigate = useNavigate();


    const [productList, setProductList] = useState([])
    const [orderData, setOrderData] = useState({
        products: [],
        productQuanity: 0,
        productPrice: 0,
        lendingAmount: 0,
        totalProductPrice: 0,
        totalLendingAmount: 0,
        totalLendingPercent: 0,
    })

    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])

    useEffect(() => {
        loading && handleGetRequest()
    }, [loading])

    useEffect(() => {
        if (userData?.borrowingCart?.items?.length > 0) {
            let tempList = [];
            let tempData = {
                productQuanity: 0,
                productPrice: 0,
                lendingAmount: 0,
                totalProductPrice: 0,
                totalLendingAmount: 0,
                totalPercentage: 0,
            }
    
            // Find the cart for the switched user
            const userCart = userData.borrowingCart.items;
            userCart.forEach(cartItem => {
                const foundProduct = productList.find(product => product.productID === cartItem.productID);
                if (foundProduct) {
                    const tempTotalLendingPrice = cartItem.productQuantity * foundProduct.lendingAmount;
                    const tempTotalPrice = cartItem.productQuantity * foundProduct.productPrice;

                    const newItem = {
                        productID: cartItem.productID,
                        productName: foundProduct.productName,
                        productQuantity: cartItem.productQuantity,
                        productPrice: foundProduct.productPrice,
                        totalPrice: tempTotalPrice,
                        totalLendingPrice: tempTotalLendingPrice,
                        lendingAmount: foundProduct.lendingAmount,
                        lendingPercentage: foundProduct.lendingPercent,
                        lendingMonths: foundProduct.lendingTenure,
                        totalQuantity: foundProduct.productQuantity,
                    };
                    tempData = {
                        productQuanity: tempData.productQuanity + cartItem.productQuantity,
                        productPrice: tempData.productPrice + foundProduct.productPrice,
                        lendingAmount: tempData.lendingAmount + foundProduct.lendingAmount,
                        totalProductPrice: tempData.totalProductPrice + tempTotalPrice,
                        totalLendingAmount: tempData.totalLendingAmount + tempTotalLendingPrice,
                        totalPercentage: tempData.totalPercentage + foundProduct.lendingPercent,
                    }
                    tempList.push(newItem);
                }
            });
    
            startTransition(() => {
                const finalPercentage = tempData.totalPercentage / tempList.length;
                setOrderData(prev => {
                    return { 
                        ...prev, 
                        productQuanity: tempData.productQuanity,
                        productPrice: tempData.productPrice,
                        lendingAmount: tempData.lendingAmount,
                        totalProductPrice: tempData.totalProductPrice,
                        totalLendingAmount: tempData.totalLendingAmount,
                        totalLendingPercent: finalPercentage,
                        products: tempList
                    };
                });
            });
        }
    }, [productList, userData.borrowingCart]);


    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(ROUTES.lendingGetProductList);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(false)
                setProductList(response.data)
            })

        }
    }

    function handleDeleteProduct(productId) {
        const updatedCart = userData?.borrowingCart?.items?.filter(item => item.productID !== productId);
        setUserData(prev => ({ ...prev, borrowingCart: {...prev.borrowingCart, items: updatedCart}  }));
        if(updatedCart.length <= 0){
            setUserData(prev => ({ ...prev, borrowingCart: {}}));
            navigate('../products')
        }
    }

    async function handleCart(productID, type) {
        let prevCart = [...(userData?.borrowingCart?.items?.length > 0 ? userData.borrowingCart.items : [])];
    
        if (type === 'add') {
           if (prevCart.length > 0) {
                if (prevCart[0].productID !== productID) {
                    FireToast({ title: 'Error', subTitle: "Can't select more than one product" });
                    return;
                } else prevCart[0].productQuantity += 1;
            } else {
                const newItem = { productID: productID, productQuantity: 1 };
                prevCart.push(newItem);
            }
        } else if (type === 'remove') {
            prevCart = prevCart
                .map(item => {
                    if (item.productID === productID) {
                        if (item.productQuantity > 1) {
                            return { ...item, productQuantity: item.productQuantity - 1 };
                        }
                        return null;
                    }
                    return item;
                })
                .filter(item => item !== null);
        }
    
        // Update the cart in the state
        startTransition(() => {
            setUserData(prev => ({ 
                ...prev, 
                borrowingCart: { 
                    ...prev.borrowingCart, 
                    items: prevCart 
                } 
            }));
        });
    
        // If the cart is empty, reset the borrowingCart and navigate to products
        if (prevCart.length <= 0) {
            setUserData(prev => ({ ...prev, borrowingCart: {} }));
            navigate('../products');
        }
    }
    

    async function handlePlaceOrder() {
        const response = await AxioRequests.HandlePostRequest({
            data: { reqUserID: userData.borrowingCart.userID , orderData: orderData },
            route: ROUTES.lendingPostCheckoutRoute,
            type: 'post',
            toastDescription: 'Order has placed successfully!'
        });
        if (response?.status === 200) {
            navigate('../lending/bills')
            startTransition(() => {
                setUserData(prev => ({ ...prev, borrowingCart: {} }))
            })

        }
    }

    if (isPending) {
        return <Loader />
    }
    return {
        orderData,
        handleCart,
        handleDeleteProduct,
        handlePlaceOrder,
    }

}

export default useLendingCheckout;