import { useEffect, useLayoutEffect, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/loader";
import { useAuthContext } from "../../context/authContext";
import AxioRequests from "../../functions/axiosRequests";
import ROUTES from "../../util/routes";
import { FireToast } from "../../context/toastContext";

const useProductList = () => {
    const [loading, setLoading] = useState(true);
    const [isPending, startTransition] = useTransition();
    const { userID, userData, setUserData } = useAuthContext();
    const navigate = useNavigate();

    const [productList, setProductList] = useState([]);
    const [selectedProductOwner, setSelectedProductOwner] = useState('');


    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])

    useEffect(() => {
        loading && handleGetRequest()
    }, [loading])

    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(ROUTES.lendingGetProductList);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(false)
                setProductList(response?.data)
            })

        }
    }

    async function handleProductDeletion(id) {
        const response = await AxioRequests.HandlePostRequest({
            route: `${ROUTES.lendingCommonProductRoute}/${id}`,
            type: 'delete',
            toastDescription: 'Product has been deleted successfully',
            data: { userID: id }
        });
        if (response.status === 200) {
            startTransition(() => {
                setLoading(true)
            })
        }
    }

    async function handleCart(id, type) {
      if (!productList || !userData) return;
    
        const selectedProduct = productList.find(product => product.productID === id);
        if (!selectedProduct) return;
    
        startTransition(() => {
            setSelectedProductOwner(selectedProduct.productOwnerID);
        });
    
        // Make a shallow copy of userData.borrowingCart
        const prevBorrowCart = { ...userData.borrowingCart };
    
        // Initialize a new cart if the userID is empty or undefined
        if (!prevBorrowCart.userID) {
            const newBorrowCart = {
                userID: selectedProduct.productOwnerID,
                userName: selectedProduct.productOwnerName,
                items: [{
                    productID: id,
                    productQuantity: 1
                }]
            };
    
            startTransition(() => {
                setUserData(prev => ({ ...prev, borrowingCart: newBorrowCart }));
            });
    
            return;
        }
    
        if (selectedProduct.productOwnerID !== prevBorrowCart.userID) {
            FireToast({ title: 'Error', subTitle: "Can't Select 2 Products from different users" });
            return; // Early return if trying to select product from different users
        }
    
        // Check if the item already exists in the cart
        const existingItemIndex = prevBorrowCart.items.findIndex(item => item.productID === id);
    
        let updatedItems;
        if (existingItemIndex >= 0) {
            // Item found in the cart
            updatedItems = prevBorrowCart.items.map((item, index) => {
                if (index === existingItemIndex) {
                    if (type === 'add') {
                        return { ...item, productQuantity: item.productQuantity + 1 };
                    } else if (type === 'remove') {
                        return item.productQuantity > 1
                            ? { ...item, productQuantity: item.productQuantity - 1 }
                            : null;
                    }
                }
                return item;
            }).filter(item => item !== null);
        } else {
            // Item not found in the cart and type is 'add'
            if (type === 'add') {
                updatedItems = [...prevBorrowCart.items, { productID: id, productQuantity: 1 }];
            } else {
                updatedItems = prevBorrowCart.items;
            }
        }
    
        const tempBorrowingCart = updatedItems.length > 0
            ? { userID: prevBorrowCart.userID, userName: prevBorrowCart.userName, items: updatedItems }
            : {};
    
        startTransition(() => {
            setUserData(prev => ({ ...prev, borrowingCart: tempBorrowingCart }));
        });
    }
    
    
    async function handleGoToCart() {
        navigate('../lending/checkout')
    }

    if (isPending) {
        return <Loader message='Waiting for server response' />;
    }

    return {
        productList,
        selectedProductOwner,
        handleProductDeletion,
        handleCart,
        handleGoToCart,
    }
}

export default useProductList;