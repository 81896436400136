import { useEffect, useLayoutEffect, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/loader";
import AxioRequests from "../../functions/axiosRequests";
import ROUTES from "../../util/routes";


const useBilling = ({userID = '', isAdmin = false, initiate = true}) => {
    const [isPending, startTransition] = useTransition()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(initiate)

    const [transactionList, setTransactionList] = useState([]);
    const [orderSummary, setOrderSummary] = useState({
        productList: [],
        orderID: '',
        orderDate: '',
        userName: '',
    });
    
    const [memberData, setMemberData] = useState({
        userDetails: {
            userName: '',
            userMobile: '',
            userAddress: '',
            userEmail: '',
        },
        billsList: []
    })

    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])

    useEffect(() => {
        if(loading){
            const finalRoute = isAdmin ? ROUTES.lendingCommonBillRoute : `${ROUTES.lendingCommonBillRoute}/${userID}`;
            handleGetRequest(finalRoute)
        }
    }, [loading, userID])

    async function handleGetRequest(route) {
        const response = await AxioRequests.HandleGetRequest(route);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(false)
                setMemberData(prev => ({...prev, ...response?.data}))
            })

        }
    }

    async function handleFetchTransactionList(orderID) {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.lendingCommonTransactionRoute}/${orderID}`);
        if (response?.status === 200) {
            startTransition(() => {
                setTransactionList(response?.data)
            })

        }
    }

    async function fetchOrderSummary(orderID) {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.lendingCommonOrderSummaryRoute}/${orderID}`);
        if (response?.status === 200) {
            startTransition(() => {
                setOrderSummary(prev => ({...prev, ...response?.data}));
            })

        }
    }

    async function handlePayment(requestObject, orderID) {
        const response = await AxioRequests.HandlePostRequest({
            route: `${ROUTES.lendingCommonTransactionRoute}/${orderID}`,
            type: 'post',
            toastDescription: 'Payment has done successfully',
            data: requestObject
        })

        if (response?.status === 200) setLoading(true)
        return response
    }

    if(isPending){
        <Loader />
    }

    return {
        memberData,
        transactionList,
        handlePayment,
        handleFetchTransactionList,
        fetchOrderSummary,
        orderSummary,
    }

}

export default useBilling;