import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState, useTransition } from 'react';
import { FireToast } from '../../context/toastContext.js';
import axiosRequests from '../../functions/axiosRequests.js';
import ROUTES from '../../util/routes.js';
import VALIDATION from '../../validation/auth.js';
import AxioRequests from '../../functions/axiosRequests.js';
import Loader from '../loader.js';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';

const Register = ({ id, handleConfirmation, showDetails, editingSelf = false }) => {
    const [isPending, startTransition] = useTransition();
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState({ latitude: '', longitude: '' });

    const [defaultValues, setDefaultValues] = useState({
        reqUserID: id,
        userName: '',
        userMobile: '',
        userAddress: '',
        userEmail: '',
        userPassword: '',
        businessSign: '',
        latitude: location.latitude,
        longitude: location.longitude,
        userType: '',
        userGST: '',
        isEdit: id !== '',
    })

    useEffect(() => {
        // Fetch latitude and longitude
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            (error) => {
                console.error("Error fetching location: ", error);
            }
        );
    }, []);

    useEffect(() => {
        if (loading && id !== '') {
            handleGetRequest()
        }

    }, [loading])

    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.CommonUserDetailRoute}/${id}`);
        if (response.status === 200) {
            startTransition(() => {
                setLoading(false)
                setDefaultValues(prev => ({ ...prev, ...response.data }))
            })

        }
    }


    if (isPending) {
        return <Loader message='Loading..' />;
    }


    return (
        <Formik
            initialValues={defaultValues}
            enableReinitialize
            validationSchema={VALIDATION.registerFormValidation}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const response = await axiosRequests.HandlePostRequest({
                        route: ROUTES.CommonUserDetailRoute, // Define your registration route
                        type: id === '' ? 'post' : 'put',
                        data: values,
                        toastDescription: `User ${id === '' ? 'has registered' : 'profile has updated'} successfully`
                    });
                    if (response?.status === 200) {
                        handleConfirmation();
                    }
                } catch (err) {
                    console.log(err);
                    FireToast({ title: 'Error', subTitle: err.response?.data?.message || 'Registration failed' });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, touched, dirty, isValid, values, setFieldValue }) => {

                return (
                    <Form className='row rounded border shadow-lg p-4 m-2'>
                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="userName">Name</FormLabel>
                            <Field
                                name="userName"
                                type="text"
                                placeholder='John Doe'
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.userName && touched.userName)}
                            />
                            <ErrorMessage name="userName" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="userMobile">Mobile</FormLabel>
                            <Field
                                name="userMobile"
                                type="number"
                                placeholder="9854XXXXXX"
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.userMobile && touched.userMobile)}
                            />
                            <ErrorMessage name="userMobile" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12'>
                            <FormLabel htmlFor="userAddress">Address</FormLabel>
                            <Field
                                name="userAddress"
                                as="textarea"
                                placeholder="Address.."
                                disabled={showDetails}
                                rows={3}
                                className={`form-control ${errors.adminReason && touched.adminReason ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="userAddress" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="userEmail">Email address</FormLabel>
                            <Field
                                name="userEmail"
                                type="email"
                                placeholder="user@example.com"
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.userEmail && touched.userEmail)}
                            />
                            <ErrorMessage name="userEmail" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="userPassword">Password</FormLabel>
                            <Field
                                name="userPassword"
                                type={showDetails ? 'text' : "password" }
                                placeholder="*****"
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.userPassword && touched.userPassword)}
                            />
                            <ErrorMessage name="userPassword" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="userType">User Type</FormLabel>
                            <Field
                                name="userType"
                                as="select"
                                className='form-control'
                                disabled={showDetails}
                                isInvalid={!!(errors.userType && touched.userType)}
                            >
                                <option value='' disabled>Select ...</option>
                                <option value='Client'>Client</option>
                                <option value='Seller'>Seller</option>
                            </Field>
                            <ErrorMessage name="userType" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="userGST">GST</FormLabel>
                            <Field
                                name="userGST"
                                type="text"
                                as={FormControl}
                                disabled={showDetails}
                                placeholder="22AAAAA0000A1Z5"
                                isInvalid={!!(errors.userGST && touched.userGST)}
                            />
                            <ErrorMessage name="userGST" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="businessSign">Business Sign</FormLabel>
                            <Field
                                name="businessSign"
                                type="text"
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.businessSign && touched.businessSign)}
                            />
                            <ErrorMessage name="businessSign" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <input type="hidden" name="latitude" value={location.latitude} />
                        <input type="hidden" name="longitude" value={location.longitude} />
                        {!showDetails &&
                            <div className='col-12 my-2 my-md-4'>
                                <Button type="submit" variant="primary" className='mx-auto d-block' disabled={!(dirty && isValid)}>
                                    {id === '' ? 'Register User' : 'Update Details'}
                                </Button>
                            </div>
                        }
                    </Form>
                )
            }}
        </Formik>
    );
};

export default Register;
