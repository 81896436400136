import { useEffect, useLayoutEffect, useState, useTransition } from "react";
import Loader from "../../component/loader";
import AxioRequests from "../../functions/axiosRequests";
import ROUTES from "../../util/routes";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";
import handleProcessedArrayData from "../../functions/processedFormData";

const useProduct = (id = '', editMode = false) => {
    const { userID } = useAuthContext();
    const [isPending, startTransition] = useTransition();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])


    const [defaultValues, setDefaultValues] = useState({
        productID: id,
        productSKU: '',
        productName: '',
        productDescription: '',
        productPrice: 0,
        sellingPrice: 0,
        isAvailable: '',
        productQuantity: '',
        productSize: '',
        productImage: '',
        isEdit: editMode,
    })

    useEffect(() => {
        if (loading && editMode) handleGetRequest()
    }, [loading, editMode])

    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.CommonProductRoute}/${id}`);
        if (response.status === 200) {
            startTransition(() => {
                setLoading(false)
                setDefaultValues(prev => ({ ...prev, ...response.data }))
            })

        }
    }

    async function handleSubmit(values) {
        const processedData = await handleProcessedArrayData(values, ['productImage']);

        const response = await AxioRequests.HandlePostRequest({
            route: ROUTES.CommonProductRoute,
            type: editMode ? 'put' : 'post',
            data: processedData,
            toastDescription: `Product has ${editMode ? 'updated' : 'created'} successfully`,
        });

        return response;
    }


    if (isPending) {
        return <Loader message='Loading..' />;
    }

    return {
        defaultValues,
        handleSubmit,
    }

}


export default useProduct;