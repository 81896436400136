import React, { startTransition, useEffect, useRef, useState } from "react";
import {
  Button,
  ListGroup,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FaFileInvoiceDollar, FaPlus } from "react-icons/fa";
import { useAuthContext } from "../../../context/authContext";
import { FireToast } from "../../../context/toastContext";
import useBilling from "../../../hooks/lending/useBilling";
import CONSTANT from "../../../util/constant";
import ModalWrapper from "../../modalWrapper";
import COMMON_FUNCIONS from "../../../functions/common";
import usePagination from "../../../hooks/usePagination";

const { transactionTypes } = CONSTANT;
const { roundupDecimalNumbers } = COMMON_FUNCIONS;

const LendingOrderList = ({ data, handlePayment }) => {
  const { userData } = useAuthContext();
  const [filteredList, setFilteredList] = useState(data);
  const { PaginationComponent, currentList } = usePagination(filteredList);

  const [totalAmount, setTotalAmount] = useState({
    lendingAmount: 0,
    appliedInterest: 0,
    totalAmount: 0,
    paidAmount: 0,
    remainingAmount: 0,
  });

  const [searchFilter, setSearchFilter] = useState("");
  const [dateFilter, setDateFilter] = useState({
    stDate: "",
    edDate: "",
  });

  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState({
    mode: "form",
    orderID: "",
    totalPayableAmount: 0,
    paidAmount: "",
    hasPrevTransaction: false,
    prevTransactionDate: "",
  });

  useEffect(() => {
    if (data?.length > 0) {
      let tempList = data;

      if (searchFilter !== "") {
        tempList = tempList.filter(
          (item) =>
            String(item.orderID)
              .toLowerCase()
              .includes(searchFilter.toLowerCase()) ||
            String(item.userName)
              .toLowerCase()
              .includes(searchFilter.toLowerCase())
        );
      }

      if (dateFilter.stDate !== "") {
        const filteredDate = new Date(dateFilter.stDate).toLocaleDateString(
          "en-GB"
        );
        tempList = tempList.filter(
          (item) =>
            new Date(item.orderDate).toLocaleDateString("en-GB") >= filteredDate
        );
      }

      if (dateFilter.edDate !== "") {
        const filteredDate = new Date(dateFilter.edDate).toLocaleDateString(
          "en-GB"
        );
        tempList = tempList.filter(
          (item) =>
            new Date(item.orderDate).toLocaleDateString("en-GB") <= filteredDate
        );
      }

      startTransition(() => {
        setFilteredList(tempList);
      });
    }
  }, [data, searchFilter, dateFilter]);

  useEffect(() => {
    if (currentList?.length > 0) {
      let tempTotalAmount = {
        lendingAmount: 0,
        appliedInterest: 0,
        totalAmount: 0,
        paidAmount: 0,
        remainingAmount: 0,
      };

      currentList?.forEach(
        (item) =>
          (tempTotalAmount = {
            lendingAmount: tempTotalAmount.lendingAmount + item.lendedAmount,
            appliedInterest:
              tempTotalAmount.appliedInterest + item.appliedInterest,
            totalAmount: tempTotalAmount.totalAmount + item.currentCapital,
            paidAmount: tempTotalAmount.paidAmount + item.paidAmount,
            remainingAmount:
              tempTotalAmount.remainingAmount + item.remainingAmount,
          })
      );

      startTransition(() => {
        setTotalAmount(tempTotalAmount);
      });
    }
  }, [currentList]);

  async function handlePaymentMenthod() {
    if (paymentData.paidAmount > paymentData.totalPayableAmount) {
      FireToast({
        title: "Error",
        subTitle: "Amount cannot be greater than total payable amount!",
      });
    } else {
      const requestObject = {
        paidAmount: paymentData.paidAmount,
        orderID: paymentData.orderID,
        hasPreviousPayment: paymentData.hasPrevTransaction,
        transactionDate: paymentData.prevTransactionDate,
      };

      const response = await handlePayment(requestObject, paymentData.orderID);
      if (response?.status === 200) {
        setPaymentModal(!paymentModal);
      }
    }
  }

  return (
    <div>
      <div className="d-flex gap-2 align-items-center justify-content-between my-2 my-md-4">
        <input
          style={{ width: "auto" }}
          type="text"
          name="searchUser"
          id="searchUser"
          placeholder="Search Bills"
          className="form-control rounded-2"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <div className="d-flex gap-2">
          <input
            type="date"
            name="stDate"
            id="stDate"
            value={dateFilter.stDate}
            onChange={(e) =>
              setDateFilter((prev) => ({ ...prev, stDate: e.target.value }))
            }
            className="form-control w-auto"
          />
          <input
            type="date"
            name="edDate"
            id="edDate"
            value={dateFilter.edDate}
            onChange={(e) =>
              setDateFilter((prev) => ({ ...prev, edDate: e.target.value }))
            }
            className="form-control w-auto"
          />
        </div>
      </div>
      <Table hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th className="text-center max-content">Lended User</th>
            <th className="text-center max-content">Lending Date</th>
            <th className="text-center max-content">Lended Items</th>
            <th className="text-center max-content">Lended Amount (INR)</th>
            <th className="text-center max-content">
              Applied Interest Rate (INR)
            </th>
            <th className="text-center max-content">Total Amount (INR)</th>
            <th className="text-center max-content">Paid Amount (INR)</th>
            <th className="text-center max-content">Remaining Amount (INR)</th>
            <th className="text-center max-content">Status</th>
            <th className="text-center max-content">Interact</th>
          </tr>
        </thead>
        <tbody>
          {currentList?.length > 0 ? (
            currentList?.map((item, index) => (
              <tr key={item.orderID} className="no-blur">
                <td>{index + 1}</td>
                <td
                  className="cursor-pointer"
                  onClick={() => {
                    setPaymentData({
                      mode: "list",
                      orderID: item.orderID,
                      paidAmount: "",
                      totalPayableAmount: item.remainingAmount,
                    });
                    setPaymentModal(true);
                  }}
                >
                  <div>
                    {item.userName}
                    <br />
                    <OverlayTrigger
                      placement="top"
                      className="h-auto"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="Order-ID" {...props}>
                          {item.orderID}
                        </Tooltip>
                      )}
                    >
                      <small className="text-truncate">
                        Bill: {item.orderID}
                      </small>
                    </OverlayTrigger>
                  </div>
                </td>
                <td className="text-center">
                  {new Date(item.orderDate).toLocaleDateString("en-GB")}
                </td>
                <td className="text-center">{item.orderQuantity}</td>
                <td className="text-center">₹ {item.lendedAmount}</td>
                <td className="text-center">
                  ₹ {roundupDecimalNumbers(item.appliedInterest, 2)}
                </td>
                <td className="text-center">
                  ₹ {roundupDecimalNumbers(item.currentCapital, 2)}
                </td>
                <td className="text-center">₹ {item.paidAmount}</td>
                <td className="text-center">
                  ₹ {roundupDecimalNumbers(item.remainingAmount, 2)}
                </td>
                <td className="text-center">
                  {item.remainingAmount === 0 ? "Paid" : "Un-Paid"}
                </td>
                <td>
                  <div className="d-flex gap-2 align-items-center justify-content-center">
                    {userData?.isAdmin && (
                      <OverlayTrigger
                        placement="top"
                        className="h-auto"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="Add-Payment" {...props}>
                            Add Payment
                          </Tooltip>
                        )}
                      >
                        <Button
                          variant="danger"
                          size="sm"
                          type="button"
                          onClick={() => {
                            setPaymentData({
                              mode: "form",
                              orderID: item.orderID,
                              paidAmount: "",
                              totalPayableAmount: item.remainingAmount,
                              hasPrevTransaction: false,
                              prevTransactionDate: "",
                            });
                            setPaymentModal(true);
                          }}
                        >
                          <FaPlus />
                        </Button>
                      </OverlayTrigger>
                    )}

                    <OverlayTrigger
                      placement="top"
                      className="h-auto"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="View-Bill" {...props}>
                          View Bill
                        </Tooltip>
                      )}
                    >
                      <Button
                        variant="danger"
                        size="sm"
                        type="button"
                        onClick={() => {
                          setPaymentData({
                            mode: "slip",
                            orderID: item.orderID,
                            paidAmount: 0,
                            totalPayableAmount: item.remainingAmount,
                          });
                          setPaymentModal(true);
                        }}
                      >
                        <FaFileInvoiceDollar />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={"5"}>No Bills found!</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr className="text-center fw-bold">
            <td colSpan="4" className="text-start">
              Total
            </td>
            <td className="text-center">₹ {totalAmount.lendingAmount}</td>
            <td className="text-center">
              ₹ {roundupDecimalNumbers(totalAmount.appliedInterest, 2)}
            </td>
            <td className="text-center">
              ₹ {roundupDecimalNumbers(totalAmount.totalAmount, 2)}
            </td>
            <td className="text-center">₹ {totalAmount.paidAmount}</td>
            <td className="text-center">
              ₹ {roundupDecimalNumbers(totalAmount.remainingAmount, 2)}
            </td>
            <td colSpan={"2"}></td>
          </tr>
        </tfoot>
      </Table>
      <PaginationComponent />
      {paymentModal ? (
        <ModalWrapper
          toggle={paymentModal}
          setToggle={setPaymentModal}
          title={
            paymentData.mode === "list"
              ? "Transaction List"
              : paymentData.mode === "slip"
              ? "Order Details"
              : "Add Payment"
          }
          hasBackground={false}
          size={
            paymentData.mode === "slip"
              ? "lg"
              : paymentData.mode === "list"
              ? "md"
              : "sm"
          }
        >
          {paymentData.mode === "list" ? (
            <TransactionList orderID={paymentData.orderID} />
          ) : paymentData.mode === "slip" ? (
            <OrderSlip orderID={paymentData.orderID} />
          ) : (
            <div className="d-flex flex-column gap-2">
              <div className="d-flex align-items-cener justify-content-between">
                <div className="text-light">Is Previous Transaction ?</div>
                <input
                  type="checkbox"
                  name="hasPrevTransaction"
                  checked={paymentData.hasPrevTransaction}
                  onChange={(e) =>
                    setPaymentData({
                      ...paymentData,
                      hasPrevTransaction: e.target.checked,
                    })
                  }
                />
              </div>
              {paymentData.hasPrevTransaction && (
                <input
                  type="datetime-local"
                  className="form-control"
                  value={paymentData.prevTransactionDate}
                  onChange={(e) =>
                    setPaymentData({
                      ...paymentData,
                      prevTransactionDate: e.target.value,
                    })
                  }
                />
              )}
              <input
                type="number"
                placeholder="Transaction Amount"
                className="form-control"
                value={paymentData.paidAmount}
                min={0}
                max={paymentData.totalPayableAmount}
                onChange={(e) =>
                  setPaymentData({ ...paymentData, paidAmount: e.target.value })
                }
              />
              <Button
                type="button"
                disabled={
                  paymentData.paidAmount === 0 ||
                  (paymentData.hasPrevTransaction &&
                    paymentData.prevTransactionDate === "")
                }
                onClick={handlePaymentMenthod}
              >
                Add Payment
              </Button>
            </div>
          )}
        </ModalWrapper>
      ) : (
        <></>
      )}
    </div>
  );
};
export default LendingOrderList;

const TransactionList = ({ orderID }) => {
  const { userID } = useAuthContext();
  const { transactionList, handleFetchTransactionList } = useBilling({
    userID: userID,
    isAdmin: false,
    initiate: false,
  });

  const [filteredList, setFilteredList] = useState(transactionList);
  const [activeTransactionType, setActiveTransactionType] = useState(
    transactionTypes[0].slug
  );

  useEffect(() => {
    handleFetchTransactionList(orderID);
  }, [orderID]);

  useEffect(() => {
    if (transactionList?.length > 0) {
      let tempList = transactionList;
      if (activeTransactionType === transactionTypes[1].slug) {
        tempList = tempList.filter(
          (item) =>
            item.transactionType.toLowerCase() === transactionTypes[1].slug
        );
      } else if (activeTransactionType === transactionTypes[2].slug) {
        tempList = tempList.filter(
          (item) =>
            item.transactionType.toLowerCase() === transactionTypes[2].slug
        );
      }

      startTransition(() => {
        setFilteredList(tempList);
      });
    }
  }, [transactionList, activeTransactionType]);

  return (
    <>
      <div className="d-flex gap-2 align-items-center justify-content-end my-2">
        {transactionTypes.map((type) => (
          <div
            key={type.id}
            className={`fw-bold fs-6 px-2 border rounded cursor-pointer ${
              activeTransactionType === type.slug
                ? "text-body-backgorund bg-light"
                : "text-light"
            }`}
            onClick={() => setActiveTransactionType(type.slug)}
          >
            {type.typeName}
          </div>
        ))}
      </div>
      <ListGroup as="ul" className="vh-50 overflow-y-scroll hidden-scrollbar">
        {filteredList?.length > 0 ? (
          <div className="d-flex gap-2 flex-column">
            {filteredList.map((item) => (
              <ListGroup.Item
                action
                as="li"
                key={item._id}
                className="d-flex justify-content-between align-items-center rounded"
              >
                <div>
                  {new Date(item?.transactionDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}{" "}
                  <br />
                  <span className="bg-body-background text-light px-2 rounded">
                    {item.transactionType}
                  </span>
                </div>
                <div
                  className={`${
                    item.transactionType.toLowerCase() ===
                    transactionTypes[1].slug.toLowerCase()
                      ? "text-danger"
                      : "text-success"
                  }`}
                >
                  {item.transactionType.toLowerCase() ===
                  transactionTypes[1].slug.toLowerCase()
                    ? "-"
                    : "+"}{" "}
                  ₹ {roundupDecimalNumbers(item?.transactionAmount, 2)}
                </div>
              </ListGroup.Item>
            ))}
          </div>
        ) : (
          <ListGroup.Item as="li">No transactions found!</ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

const OrderSlip = ({ orderID }) => {
  const { userID } = useAuthContext();
  const { orderSummary, fetchOrderSummary } = useBilling({
    userID: userID,
    isAdmin: false,
    initiate: false,
  });
  const [totalLendedAmount, setTotalLendedAmount] = useState(0);

  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload();
  };

  useEffect(() => {
    fetchOrderSummary(orderID);
  }, [orderID]);

  useEffect(() => {
    if (orderSummary?.productList?.length > 0) {
      let tempTotal = 0;
      orderSummary.productList.forEach(
        (item) => (tempTotal += item.lendedAmount * item.productQuantity)
      );

      startTransition(() => setTotalLendedAmount(tempTotal));
    }
  }, [orderSummary]);

  return (
    <>
      <div ref={printRef}>
        <div className="p-2 border rounded border-light">
          <div className="d-flex gap-1 flex-column text-light mb-2 ">
            <div className="fw-bold fs-2 text-center">SS Metal</div>
            <div className="text-center">
              <small>
                GST: <b>09EXLPR3314G1Z7</b>
              </small>{" "}
              |{" "}
              <small>
                Phone No: <b>+91 9368908554</b>
              </small>
            </div>
            <small className="text-center">
              Dashwa Ghat Bangla Gaon, Civil Lines Uttar Pradesh (IN){" "}
            </small>

            <hr className="bg-light my-0" />
            <div className="d-flex w-100 justify-content-between">
              <small>
                Borrower Name: <b className="fs-6">{orderSummary.userName}</b>
              </small>
              <small>
                Date:{" "}
                <b className="fs-6">
                  {new Date(orderSummary?.orderDate).toLocaleDateString(
                    "en-GB"
                  )}
                </b>
              </small>
            </div>
            <hr className="bg-light my-0" />
          </div>
          <Table hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th className="max-content">Product Detail</th>
                <th className="text-center max-content">Quantity</th>
                <th className="text-center max-content">Lended Amount</th>
                <th className="text-center max-content">Total Lended Amount</th>
                <th className="text-center max-content">Lended Percentage</th>
                <th className="text-center max-content">Lended Tenure</th>
              </tr>
            </thead>
            <tbody>
              {orderSummary?.productList?.length > 0 ? (
                orderSummary?.productList?.map((item, index) => (
                  <tr key={item.productID} className="no-blur">
                    <td>{index + 1}</td>
                    <td>{item.productName}</td>
                    <td className="text-center">
                      {item.productQuantity}{" "}
                      {item.productQuantity > 1 ? "PCs" : "PC"}
                    </td>
                    <td className="text-center">₹ {item.lendedAmount}</td>
                    <td className="text-center">
                      ₹ {item.lendedAmount * item.productQuantity}
                    </td>
                    <td className="text-center">{item.lendedPercentage} %</td>
                    <td className="text-center">
                      {item.lendedTenure}{" "}
                      {item.lendedTenure > 1 ? "Months" : "Month"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={"5"}>No Products found!</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr className="border rounded">
                <td colSpan="5" className="fw-bold">
                  Total Amount
                </td>
                <td className="fw-bold text-center" colSpan="1">
                  ₹ {totalLendedAmount}
                </td>
                <td colSpan="2"></td>
              </tr>
            </tfoot>
          </Table>
          <div className="text-light pb-3">Terms & Condition:</div>
          <div className="text-light text-end mt-3">Authorize Signature</div>
        </div>
      </div>
      <Button
        type="button"
        className="w-auto ms-auto d-block my-2"
        onClick={handlePrint}
      >
        Print Bill
      </Button>
    </>
  );
};
