import React, { useEffect, useState, startTransition, useRef } from "react";
import {
  Button,
  ListGroup,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FaFileInvoiceDollar, FaPlus, FaTrashAlt } from "react-icons/fa";
import { FireToast } from "../../../context/toastContext";
import useMyOrder from "../../../hooks/billing/useMyOrder";
import ModalWrapper from "../../modalWrapper";
import { useAuthContext } from "../../../context/authContext";
import usePagination from "../../../hooks/usePagination";

const BillList = ({ data, handlePayment, handleDelete }) => {
  const { userData } = useAuthContext();
  const [filteredList, setFilteredList] = useState(data);
  const { PaginationComponent, currentList } = usePagination(filteredList);

  const [totalAmount, setTotalAmount] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [dateFilter, setDateFilter] = useState({
    stDate: "",
    edDate: "",
  });

  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState({
    mode: "form",
    orderID: "",
    totalPayableAmount: 0,
    paidAmount: 0,
  });

  useEffect(() => {
    if (data?.length > 0) {
      let tempList = data;

      if (searchFilter !== "") {
        tempList = tempList.filter(
          (item) =>
            String(item.orderID)
              .toLowerCase()
              .includes(searchFilter.toLowerCase()) ||
            String(item.userName)
              .toLowerCase()
              .includes(searchFilter.toLowerCase())
        );
      }

      if (dateFilter.stDate !== "") {
        const filteredDate = new Date(dateFilter.stDate).toLocaleDateString(
          "en-GB"
        );
        tempList = tempList.filter(
          (item) =>
            new Date(item.orderDate).toLocaleDateString("en-GB") >= filteredDate
        );
      }

      if (dateFilter.edDate !== "") {
        const filteredDate = new Date(dateFilter.edDate).toLocaleDateString(
          "en-GB"
        );
        tempList = tempList.filter(
          (item) =>
            new Date(item.orderDate).toLocaleDateString("en-GB") <= filteredDate
        );
      }

      startTransition(() => {
        setFilteredList(tempList);
      });
    }
  }, [data, searchFilter, dateFilter]);

  useEffect(() => {
    if (currentList?.length > 0) {
      let totalAmount = 0;
      currentList?.forEach((item) => (totalAmount += item.remainingAmount));

      startTransition(() => {
        setTotalAmount(totalAmount);
      });
    }
  }, [currentList]);

  async function handlePaymentMenthod(amount, maxPayableAmount, orderID) {
    if (amount > maxPayableAmount) {
      FireToast({
        title: "Error",
        subTitle: "Amount cannot be greater than total payable amount!",
      });
    } else {
      const response = await handlePayment(amount, orderID);
      if (response?.status === 200) {
        setPaymentModal(!paymentModal);
      }
    }
  }

  return (
    <div>
      <div className="d-flex flex-column flex-md-row gap-2 align-items-center justify-content-between my-2 my-md-4">
        <input
          style={{ width: "100%" }}
          type="text"
          name="searchUser"
          id="searchUser"
          placeholder="Search Bills"
          className="form-control rounded-2"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
        <div style={{width:'100%'}} className="d-flex flex-md-row gap-2 mt-2 mt-md-0">
          <section
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "space-evenly",
              width: "50%",
            }}
          >
            {" "}
            <span
              style={{
                color: "white",
                fontWeight: 500,
                fontSize: "large",
              }}
            >
              From:{" "}
            </span>
            <input
              type="date"
              name="stDate"
              id="stDate"
              value={dateFilter.stDate}
              onChange={(e) =>
                setDateFilter((prev) => ({ ...prev, stDate: e.target.value }))
              }
              className="form-control w-md-auto"
              style={{ width: "70%" }}
            />
          </section>
          <section
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "space-evenly",
              width: "50%",
            }}
          >
            {" "}
            <span
              style={{
                color: "white",
                fontWeight: 500,
                fontSize: "large",
              }}
            >
              To:  {" "}
            </span>
            <input
              type="date"
              name="edDate"
              id="edDate"
              value={dateFilter.edDate}
              onChange={(e) =>
                setDateFilter((prev) => ({ ...prev, edDate: e.target.value }))
              }
              className="form-control w-md-auto"
              style={{ width: "70%" }}
            />
          </section>
        </div>
      </div>

      <Table style={{ width: "100%" }} hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th className="max-content">Bill ID</th>
            <th className="text-center max-content">Bill Date</th>
            <th className="text-center max-content">Items</th>
            <th className="text-center max-content">Amount</th>
            <th className="text-center max-content">Paid Amount</th>
            <th className="text-center max-content">Remaining Amount</th>
            <th className="text-center max-content">Status</th>
            <th className="text-center max-content">Interact</th>
          </tr>
        </thead>
        <tbody>
          {currentList?.length > 0 ? (
            currentList.map((item, index) => (
              <tr key={item.orderID} className="no-blur">
                <td>{index + 1}</td>
                <td
                  className="cursor-pointer"
                  onClick={() => {
                    setPaymentData({
                      mode: "list",
                      orderID: item.orderID,
                      paidAmount: 0,
                      totalPayableAmount: item.remainingAmount,
                    });
                    setPaymentModal(true);
                  }}
                >
                  <div>
                    {item.userName}
                    <br />
                    <OverlayTrigger
                      placement="top"
                      className="h-auto"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="Order-ID" {...props}>
                          {item.orderID}
                        </Tooltip>
                      )}
                    >
                      <small className="text-truncate">
                        Bill: {item.orderID}
                      </small>
                    </OverlayTrigger>
                  </div>
                </td>
                <td className="text-center">
                  {new Date(item.orderDate).toLocaleDateString("en-GB")}
                </td>
                <td className="text-center">{item.totalItems}</td>
                <td className="text-center">₹ {item.orderAmount}</td>
                <td className="text-center">₹ {item.paidAmount}</td>
                <td className="text-center">₹ {item.remainingAmount}</td>
                <td className="text-center">
                  {item.remainingAmount === 0 ? "Paid" : "Un-Paid"}
                </td>
                <td>
                  <div className="d-flex gap-2 align-items-center justify-content-center">
                    {userData?.isAdmin && (
                      <div className="d-flex gap-2">
                        <OverlayTrigger
                          placement="top"
                          className="h-auto"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="Add-Payment" {...props}>
                              Add Payment
                            </Tooltip>
                          )}
                        >
                          <Button
                            variant="danger"
                            size="sm"
                            type="button"
                            onClick={() => {
                              setPaymentData({
                                mode: "form",
                                orderID: item.orderID,
                                paidAmount: 0,
                                totalPayableAmount: item.remainingAmount,
                              });
                              setPaymentModal(true);
                            }}
                          >
                            <FaPlus />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          className="h-auto"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="Delete Order" {...props}>
                              Delete Order
                            </Tooltip>
                          )}
                        >
                          <Button
                            variant="danger"
                            size="sm"
                            type="button"
                            onClick={() => handleDelete(item.orderID)}
                          >
                            <FaTrashAlt />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}

                    <OverlayTrigger
                      placement="top"
                      className="h-auto"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="View-Bill" {...props}>
                          View Bill
                        </Tooltip>
                      )}
                    >
                      <Button
                        variant="danger"
                        size="sm"
                        type="button"
                        onClick={() => {
                          setPaymentData({
                            mode: "slip",
                            orderID: item.orderID,
                            paidAmount: 0,
                            totalPayableAmount: item.remainingAmount,
                          });
                          setPaymentModal(true);
                        }}
                      >
                        <FaFileInvoiceDollar />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No Bills found!</td>
            </tr>
          )}
        </tbody>

        <tfoot>
          <tr className="text-center fw-bold">
            <td colSpan="6" className="text-start">
              Total
            </td>
            <td>₹ {totalAmount}</td>
            <td colSpan={"2"}></td>
          </tr>
        </tfoot>
      </Table>
      <PaginationComponent />
      {paymentModal ? (
        <ModalWrapper
          toggle={paymentModal}
          setToggle={setPaymentModal}
          title={
            paymentData.mode === "list"
              ? "Transaction List"
              : paymentData.mode === "slip"
              ? "Order Details"
              : "Add Payment"
          }
          hasBackground={false}
          size={
            paymentData.mode === "slip"
              ? "lg"
              : paymentData.mode === "list"
              ? "md"
              : "sm"
          }
        >
          {paymentData.mode === "list" ? (
            <TransactionList orderID={paymentData.orderID} />
          ) : paymentData.mode === "slip" ? (
            <OrderSlip orderID={paymentData.orderID} />
          ) : (
            <div className="d-flex flex-column gap-2">
              <input
                type="number"
                className="form-control"
                value={paymentData.paidAmount}
                min={0}
                max={paymentData.totalPayableAmount}
                onChange={(e) =>
                  setPaymentData({ ...paymentData, paidAmount: e.target.value })
                }
              />
              <Button
                type="button"
                disabled={paymentData.paidAmount === 0}
                onClick={() =>
                  handlePaymentMenthod(
                    paymentData.paidAmount,
                    paymentData.totalPayableAmount,
                    paymentData.orderID
                  )
                }
              >
                Add Payment
              </Button>
            </div>
          )}
        </ModalWrapper>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BillList;

const TransactionList = (orderID) => {
  const { userID } = useAuthContext();
  const { transactionList, handleFetchTransactionList } = useMyOrder({
    userID,
    isAdmin: false,
  });

  useEffect(() => {
    handleFetchTransactionList(orderID.orderID);
  }, [orderID]);

  return (
    <ListGroup as="ul">
      {transactionList?.length > 0 ? (
        <div className="d-flex gap-2 flex-column">
          {transactionList.map((item) => (
            <ListGroup.Item
              action
              as="li"
              key={item._id}
              className="d-flex justify-content-between rounded"
            >
              <div>
                {new Date(item?.transactionDate).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </div>
              <div>₹ {item?.transactionAmount}</div>
            </ListGroup.Item>
          ))}
        </div>
      ) : (
        <ListGroup.Item as="li">No transactions found!</ListGroup.Item>
      )}
    </ListGroup>
  );
};

const OrderSlip = ({ orderID }) => {
  const { userID } = useAuthContext();
  const { orderSummary, fetchOrderSummary } = useMyOrder({
    userID,
    isAdmin: false,
  });

  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload();
  };

  useEffect(() => {
    fetchOrderSummary(orderID);
  }, [orderID]);

  return (
    <>
      <div ref={printRef}>
        <div className="p-2 border rounded border-light">
          <div className="d-flex gap-1 flex-column text-light mb-2 ">
            <div className="fw-bold fs-2 text-center">SS Metal</div>
            <div className="text-center">
              <small>
                GST: <b>09EXLPR3314G1Z7</b>
              </small>{" "}
              |{" "}
              <small>
                Phone No: <b>+91 9368908554</b>
              </small>
            </div>
            <small className="text-center">
              Dashwa Ghat Bangla Gaon, Civil Lines Uttar Pradesh (IN){" "}
            </small>
            <hr className="bg-light my-0" />
            <div className="d-flex w-100 justify-content-between">
              <small>
                Reference No: <b className="fs-6">{orderSummary?.userGST}</b>
              </small>{" "}
              <small>
                Date:{" "}
                <b className="fs-6">
                  {new Date(orderSummary?.orderDate).toLocaleDateString(
                    "en-GB"
                  )}
                </b>
              </small>
            </div>
            <div className="text-left">
              <small>
                Name: <b className="fs-6">{orderSummary.userName}</b>
              </small>
            </div>
          </div>
          <Table hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th className="max-content">Product Detail</th>
                <th className="text-center max-content">Quantity</th>
                <th className="text-center max-content">Rate</th>
                <th className="text-center max-content">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {orderSummary?.productList?.length > 0 ? (
                orderSummary?.productList?.map((item, index) => (
                  <tr key={item.productID} className="no-blur">
                    <td>{index + 1}</td>
                    <td>{item.productName}</td>
                    <td className="text-center">{item.productQuantity}</td>
                    <td className="text-center">₹ {item.productPrice}</td>
                    <td className="text-center">
                      ₹ {item.productPrice * item.productQuantity}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={"5"}>No Products found!</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="3"></td>
                <td className="fw-bold px-0">Total</td>
                <td className="fw-bold text-center">
                  ₹ {orderSummary.totalAmount}
                </td>
              </tr>
              {orderSummary?.totalTaxes?.map((tax, index) => (
                <tr key={index} className="border rounded">
                  <td className="dark-theme p-0" colSpan="3"></td>
                  <td className="dark-theme p-0" colSpan="1">
                    {tax.taxName}
                  </td>
                  <td className="dark-theme text-center p-0" colSpan="1">
                    + ₹ {tax.amount}
                  </td>
                </tr>
              ))}
              <tr className="border rounded">
                <td className="dark-theme p-0" colSpan="3"></td>
                <td className="dark-theme p-0" colSpan="1">
                  Discount
                </td>
                <td className="dark-theme text-center p-0" colSpan="1">
                  - ₹ {orderSummary?.totalDiscount?.amount}
                </td>
              </tr>
              <tr className="border rounded">
                <td colSpan="3"></td>
                <td className="fw-bold px-0">Total Amount</td>
                <td className="fw-bold text-center" colSpan="1">
                  ₹ {orderSummary?.finalAmount}
                </td>
              </tr>
            </tfoot>
          </Table>
          <div className="text-light pb-3">Terms & Condition:</div>
          <div className="text-light text-end mt-3">Authorize Signature</div>
        </div>
      </div>
      <Button
        type="button"
        className="w-auto ms-auto d-block my-2"
        onClick={handlePrint}
      >
        Print Bill
      </Button>
    </>
  );
};
