import { useEffect, useLayoutEffect, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/loader";
import { useAuthContext } from "../../context/authContext";
import AxioRequests from "../../functions/axiosRequests";
import ROUTES from "../../util/routes";

const useLendingMembersList = () => {
    const [loading, setLoading] = useState(true);
    const [isPending, startTransition] = useTransition();
    const { userID, setUserData } = useAuthContext();
    const navigate = useNavigate();

    const [userList, setUserList] = useState([]);
    
    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])

    useEffect(() => {
        loading && handleGetRequest()
    }, [loading])

    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(ROUTES.lendingCommonUserDetailRoute);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(false)
                setUserList(response.data)
            })

        }
    }

    async function handleUserDelete(id) {
        const response = await AxioRequests.HandlePostRequest({
            route: `${ROUTES.lendingCommonUserDetailRoute}/${id}`,
            type: 'delete',
            toastDescription: 'User has been deleted successfully',
            data: { userID: id }
        });
        if (response.status === 200) {
            startTransition(() => {
                setLoading(true)
            })
        }
    }

    async function handleUserStatus(id, value) {
        const response = await AxioRequests.HandlePostRequest({
            route: `${ROUTES.lendingPutUserStatusRoute}/${id}`,
            type: 'put',
            toastDescription: `User has ${value ? 'Activated' : 'De-Activated'} successfully`,
            data: { isActive: value, reqUserID: id }
        });

        if (response.status === 200) {
            startTransition(() => {
                setLoading(true);
            });
        }
    }

    if (isPending) {
        return <Loader message='Waiting for server response' />;
    }

    return {
        userList,
        handleUserStatus,
        handleUserDelete,
        setLoading,
        startTransition,
    };
}

export default useLendingMembersList;