import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Select from 'react-select';
import { FireToast } from '../../../context/toastContext.js';
import useProductForm from '../../../hooks/lending/useProductForm.js';
import VALIDATION from '../../../validation/product.js';

const LendingProductForm = ({ id, handleConfirmation, editMode = false }) => {
    const { defaultValues, partnerList, userList, handleSubmit } = useProductForm(id, editMode);
    const [userSelected, setUserSelected] = useState(editMode);

    return (
        <Formik
            initialValues={defaultValues}
            enableReinitialize
            validationSchema={VALIDATION.lendingProductFormValidation}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const response = await handleSubmit(values);
                    response?.status === 200 && handleConfirmation();
                } catch (err) {
                    console.log(err);
                    FireToast({ title: 'Error', subTitle: err.response?.data?.message });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, touched, dirty, values, isValid, setFieldValue }) => {
                return (
                    <Form className='row rounded border shadow-lg p-4 m-2'>
                    {userSelected === false ? 
                        <>
                            <FormGroup className='col-12 mb-2'>
                                <FormLabel htmlFor="reqUserID">Borrower Name</FormLabel>                               
                                <Select
                                    value={userList.find(option => option.userID === values.reqUserID) || null}
                                    getOptionLabel={(option) => option.userName}
                                    getOptionValue={(option) => option.userID}
                                    options={userList}
                                    onChange={(selectedValue) => {
                                        setFieldValue('reqUserID', selectedValue.userID)
                                    }}
                                    placeholder="Select an option"
                                />
                                <ErrorMessage name="reqUserID" component="div" className="invalid-feedback" />
                            </FormGroup> 
                            <Button type='button' disabled={values.reqUserID === null || values.reqUserID === ''} className='m-2 my-md-4' onClick={() => setUserSelected(true)} >Continue</Button>
                        </>
                    : ( <>
                        <FormGroup className='col-12 mb-2'>
                            <FormLabel htmlFor="productName">Product Name</FormLabel>
                            <Field
                                name="productName"
                                type="text"
                                placeholder='Product Name'
                                as={FormControl}
                                isInvalid={!!(errors.productName && touched.productName)}
                            />
                            <ErrorMessage name="productName" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="startDate">Lending Start Date</FormLabel>
                            <Field
                                name="startDate"
                                type="date"
                                as={FormControl}
                                isInvalid={!!(errors.startDate && touched.startDate)}
                            />
                            <ErrorMessage name="startDate" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productPrice">Product Price</FormLabel>
                            <Field
                                name="productPrice"
                                placeholder='0'
                                min={0}

                                type="number"
                                as={FormControl}
                                isInvalid={!!(errors.productPrice && touched.productPrice)}
                            />
                            <ErrorMessage name="productPrice" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="lendingPartnerID">Partner Name</FormLabel>                               
                            <Select
                                value={partnerList.find(option => option._id === values.lendingPartnerID) || null}
                                getOptionLabel={(option) => `${option.name}-${option.mobile}`}
                                getOptionValue={(option) => option._id}
                                options={partnerList}
                                onChange={(selectedValue) => {
                                    setFieldValue('lendingPartnerID', selectedValue._id)
                                }}
                                placeholder="Select an option"
                            />
                            <ErrorMessage name="lendingPartnerID" component="div" className="invalid-feedback" />
                        </FormGroup> 
                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="lendingPartnerInterest">Partner Interest</FormLabel>
                            <Field
                                name="lendingPartnerInterest"
                                placeholder='0'
                                min={0}
                                type="number"
                                step="0.1"
                                as={FormControl}
                                isInvalid={!!(errors.lendingPartnerInterest && touched.lendingPartnerInterest)}
                            />
                            <ErrorMessage name="lendingPartnerInterest" component="div" className="invalid-feedback" />
                        </FormGroup>
                        

                        <FormGroup className='col-12 mb-2'>
                            <FormLabel htmlFor="productDescription">Product Description</FormLabel>
                            <Field
                                name="productDescription"
                                as="textarea"
                                placeholder='Detail about Product'
                                rows={4}
                                className={`form-control ${errors.productDescription && touched.productDescription ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="productDescription" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup className='col-12 mb-2'>
                            <FormLabel htmlFor="lendingTerms">Lending Terms</FormLabel>
                            <Field
                                name="lendingTerms"
                                as="textarea"
                                placeholder='Detailed Lending Terms'
                                rows={4}
                                className={`form-control ${errors.lendingTerms && touched.lendingTerms ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="lendingTerms" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productSize">Product Size</FormLabel>
                            <Field
                                name="productSize"
                                placeholder='Product Size'
                                type="text"
                                as={FormControl}
                                isInvalid={!!(errors.productSize && touched.productSize)}
                            />
                            <ErrorMessage name="productSize" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productWeight">Product Weight (GM)</FormLabel>
                            <Field
                                name="productWeight"
                                placeholder='Product Weight'
                                type="text"
                                as={FormControl}
                                isInvalid={!!(errors.productWeight && touched.productWeight)}
                            />
                            <ErrorMessage name="productWeight" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productImage">Product Image</FormLabel>
                            <input
                                type='file'
                                name='productImage'
                                id='productImage'
                                as={FormControl}
                                className='form-control'
                                onChange={e => setFieldValue('productImage', e.target.files[0])}
                            />
                            <ErrorMessage name="productImage" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup className='col-12 col-md-6 mb-2'>
                            <FormLabel htmlFor="productQuantity">Product Quantity</FormLabel>
                            <Field
                                name="productQuantity"
                                type="number"
                                placeholder='0'
                                min={0}
                                as={FormControl}
                                isInvalid={!!(errors.productQuantity && touched.productQuantity)}
                            />
                            <ErrorMessage name="productQuantity" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-4 mb-2'>
                            <FormLabel htmlFor="lendingAmount">Lending Amount</FormLabel>
                            <Field
                                name="lendingAmount"
                                placeholder='0'
                                type="number"
                                min={0}
                                as={FormControl}
                                isInvalid={!!(errors.lendingAmount && touched.lendingAmount)}
                            />
                            <ErrorMessage name="lendingAmount" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup className='col-12 col-md-4 mb-2'>
                            <FormLabel htmlFor="lendingPercent">Lending Percent</FormLabel>
                            <Field
                                name="lendingPercent"
                                placeholder='0'
                                type="number"
                                min={0}
                                max={100}
                                as={FormControl}
                                isInvalid={!!(errors.lendingPercent && touched.lendingPercent)}
                            />
                            <ErrorMessage name="lendingPercent" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup className='col-12 col-md-4 mb-2'>
                            <FormLabel htmlFor="lendigTenure">Lending Tenure (Months)</FormLabel>
                            <Field
                                name="lendigTenure"
                                placeholder='0'
                                type="number"
                                min={0}
                                as={FormControl}
                                isInvalid={!!(errors.lendigTenure && touched.lendigTenure)}
                            />
                            <ErrorMessage name="lendigTenure" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <div className='col-12 my-2 my-md-4'>
                            <Button type="submit" variant="primary" className='mx-auto d-block' disabled={!(dirty && isValid)}>
                                {editMode ? 'Update Product Details' : 'Create New Product'}
                            </Button>
                        </div>
                    </>)}
                    </Form>
                )
            }}
        </Formik>
    );
};

export default LendingProductForm;
