import React from 'react'
import useMyOrder from '../../../hooks/billing/useMyOrder'
import Loader from '../../loader'
import BillList from './transactionList'

const MyBills = ({userID}) => {
    const { memberData, handlePayment, isPending, loading, totalPendencies, handleOrderDelete } = useMyOrder({userID:userID, isAdmin:false});
    
    if (isPending) {
        return <Loader />
    }

    if(loading?.initial){
        return <Loader />
    }

    return (
        <>
            <div className='fw-bold d-flex flex-column gap-2 align-items-center justify-content-center border rounded border-light p-2 px-md-4 my-2 my-md-4 text-light'>
                <div className='fs-2 fw-bold'>{memberData?.userDetails?.userName} <span className='fw-bold bg-light text-body-background fs-6 px-2 rounded'>{memberData?.userDetails?.userType}</span></div>
                <div><small className='fw-normal'>Email: </small> {memberData?.userDetails?.userEmail} |  <small className='fw-normal'>Mobile: </small> +91 {memberData?.userDetails?.userMobile} |  <small className='fw-normal'>GST: </small> {memberData?.userDetails?.GST}</div>               
                <div><small className='fw-normal'>Address: </small>{memberData?.userDetails?.userAddress}</div>
            </div>

            <div className='d-flex justify-content-between gap-2 align-items-center'>
                <h2 className='text-light'> My Bills</h2>
            </div>
            <BillList data={memberData?.billsList} handlePayment={handlePayment} handleDelete={(orderID) => handleOrderDelete(orderID)} />
        </>
    )
}

export default MyBills