// const BACKEND_PORT = 'https://ut-billing.onrender.com';
// const IMAGE_URL = 'https://ut-billing.onrender.com/uploads'

const BACKEND_PORT = 'https://backend.brassify.in:8081';
const IMAGE_URL = 'https://backend.brassify.in:8081/uploads'


// Auth Routes
const postLoginRoute = `${BACKEND_PORT}/auth/login`;
const postLogoutRoute = `${BACKEND_PORT}/auth/logout`;
// User Management Routes
const CommonUserDetailRoute = `${BACKEND_PORT}/auth/userList`;
const putUserStatusRoute = `${BACKEND_PORT}/auth/userList/status`;

// Product Routes
const CommonProductRoute = `${BACKEND_PORT}/admin/product/form`;
const getProductList = `${BACKEND_PORT}/admin/product/list`;

// Checkout Routes
const postCartRoute = `${BACKEND_PORT}/admin/product/cart`;
const postCheckoutRoute = `${BACKEND_PORT}/admin/product/checkout`;

// Billing Routes
const CommonBillRoute = `${BACKEND_PORT}/admin/product/bills`; 
const CommonTransactionRoute = `${BACKEND_PORT}/admin/product/bills/transaction`;
const CommonOrderSummaryRoute = `${BACKEND_PORT}/admin/product/bills/summary`;

// Report
const CommonProductReportRoute = `${BACKEND_PORT}/admin/product/report` 


// Lending Management System
const lendingPostLoginRoute = `${BACKEND_PORT}/auth/lending/login`;
const lendingPostLogoutRoute = `${BACKEND_PORT}/auth/lending/logout`;
const lendingCommonUserDetailRoute = `${BACKEND_PORT}/auth/lending/userList`;
const lendingPutUserStatusRoute = `${BACKEND_PORT}/auth/lending/userList/status`;

const lendingCommonPartnerRoute = `${BACKEND_PORT}/auth/lending/partner`;


const lendingCommonProductRoute = `${BACKEND_PORT}/admin/product/lending/form`;
const lendingGetProductList = `${BACKEND_PORT}/admin/product/lending/list`;

const lendingPostCheckoutRoute = `${BACKEND_PORT}/admin/product/lending/checkout`;
const lendingCommonBillRoute = `${BACKEND_PORT}/admin/product/lending/bills`;
const lendingCommonTransactionRoute = `${BACKEND_PORT}/admin/product/lending/bills/transaction`;
const lendingCommonOrderSummaryRoute = `${BACKEND_PORT}/admin/product/lending/bills/summary`;




const ROUTES = {
    IMAGE_URL,
    postLoginRoute, postLogoutRoute,
    // User Management Routes
    CommonUserDetailRoute, putUserStatusRoute,
    // Product Routes
    CommonProductRoute, getProductList,
    // Checkout Route
    postCartRoute, postCheckoutRoute,
    // Billing Routes
    CommonBillRoute, CommonTransactionRoute, CommonOrderSummaryRoute, CommonProductReportRoute,

    // Lending Routes
    lendingPostLoginRoute, lendingPostLogoutRoute, lendingCommonUserDetailRoute, lendingPutUserStatusRoute, lendingCommonPartnerRoute,
    lendingCommonProductRoute, lendingGetProductList,
    lendingPostCheckoutRoute, lendingCommonBillRoute, lendingCommonTransactionRoute, lendingCommonOrderSummaryRoute
}

export default ROUTES 