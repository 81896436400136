import { useEffect, useLayoutEffect, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/loader";
import { useAuthContext } from "../../context/authContext";
import AxioRequests from "../../functions/axiosRequests";
import ROUTES from "../../util/routes";

const useLendingPartners = () => {
    const [loading, setLoading] = useState(true);
    const [isPending, startTransition] = useTransition();
    const navigate = useNavigate();
    const {userID} = useAuthContext();

    const [userList, setUserList] = useState([]);
    
    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])

    useEffect(() => {
        loading && handleGetRequest()
    }, [loading])

    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(ROUTES.lendingCommonPartnerRoute);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(false)
                setUserList(response.data)
            })

        }
    }

    async function handleUserDelete(id) {
        const response = await AxioRequests.HandlePostRequest({
            route: `${ROUTES.lendingCommonPartnerRoute}/${id}`,
            type: 'delete',
            toastDescription: 'Partner has been deleted successfully',
            data: { userID: id }
        });
        if (response.status === 200) {
            startTransition(() => {
                setLoading(true)
            })
        }
    }

    if (isPending) {
        return <Loader message='Waiting for server response' />;
    }

    return {
        userList,
        handleUserDelete
    };
}

export default useLendingPartners;