import { useEffect, useState, useLayoutEffect, useTransition } from "react"
import { useAuthContext } from "../../context/authContext"
import AxioRequests from "../../functions/axiosRequests"
import ROUTES from "../../util/routes"
import { useNavigate } from "react-router-dom"
import Loader from "../../component/loader"

const useCheckout = () => {
    const [loading, setLoading] = useState(true)
    const [isPending, startTransition] = useTransition()
    const { userID, userData, setUserData } = useAuthContext()
    const navigate = useNavigate();


    const [productList, setProductList] = useState([])

    const [tempProductQty, setTempProductQty] = useState({})
    const [tempProductPrice, setTempProductPrice] = useState({})

    const [orderData, setOrderData] = useState({
        products: [],
        subTotal: 0,
        discounts: {
            percent: 0,
            amount: 0,
        },
        taxes: {
            IGST: {
                percent: 0,
                amount: 0,
            },
            CGST: {
                percent: 0,
                amount: 0,
            },
            SGST: {
                percent: 0,
                amount: 0,
            },
        },
        totalTaxes: 0,
        totalAmount: 0,
    })

    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])

    useEffect(() => {
        loading && handleGetRequest()
    }, [loading])

    useEffect(() => {
        const switchedUser = userData?.switchedUser;
        const switchedUserID = switchedUser?.userID;
    
        if (userData?.cart?.length > 0 && switchedUserID) {
            let tempList = [];
            let tempSubTotal = 0;
    
            // Find the cart for the switched user
            const userCart = userData.cart.find(cart => cart.userID === switchedUserID);
            let tempProductCartPrice = {};
            let tempProductCartQty = {};
            if (userCart?.items?.length > 0) {
                userCart.items.forEach(cartItem => {
                    const foundProduct = productList.find(product => product.productID === cartItem.id);
                    if (foundProduct) {
                        const productPrice = switchedUser?.userRole === 'Seller' ? foundProduct.productPrice : foundProduct.sellingPrice;
                        tempProductCartPrice[cartItem.id] = productPrice;
                        tempProductCartQty[cartItem.id] = cartItem.quantity;
                        const newItem = {
                            productID: cartItem.id,
                            productQuantity: cartItem.quantity,
                            productPrice: productPrice,
                            productName: foundProduct.productName,
                            totalQuantity: foundProduct.productQuantity,
                        };
                        tempSubTotal += productPrice * cartItem.quantity;
                        tempList.push(newItem);
                    }
                });
            }
    
            startTransition(() => {
                setTempProductQty(tempProductCartQty);
                setTempProductPrice(tempProductCartPrice);
                setOrderData(prev => {
                    const newTotalAmount = calculateTotalAmount(tempSubTotal, prev.discounts, prev.taxes);
                    return { ...prev, subTotal: tempSubTotal, products: tempList, totalAmount: newTotalAmount };
                });
            });
        }
    }, [productList, userData.cart, userData.switchedUser?.userID]);

    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(ROUTES.getProductList);
        if (response?.status === 200) {
            startTransition(() => {
                setLoading(false)
                setProductList(response.data)
            })

        }
    }

    function handleDeleteProduct(productId) {
        const switchedUser = userData?.switchedUser?.userID;
        const updatedCart = userData?.cart?.map(userCart => {
          if (userCart.userID === switchedUser) {
            const updatedItems = userCart.items.filter(item => item.id !== productId);
            return { ...userCart, items: updatedItems };
          }
          return userCart;
        }).filter(userCart => userCart.items.length > 0);
      
        setUserData(prev => ({ ...prev, cart: updatedCart }));
    }

    async function handleCart(productID, type) {
        const switchedUserID = userData?.switchedUser?.userID;
        const prevCart = [...(userData?.cart?.length > 0 ? userData.cart : [])];
    
        // Find the user's cart
        const userCartIndex = prevCart.findIndex(cart => cart.userID === switchedUserID);
    
        // If the user is not found in the cart, add a new cart entry for the user
        if (userCartIndex === -1) {
            const newUserCart = {
                userID: switchedUserID,
                items: [{ id: productID, quantity: 1 }]
            };
    
            startTransition(() => {
                setUserData(prev => ({ ...prev, cart: [...prev.cart, newUserCart] }));
            });
    
            return;
        }
    
        const userCart = prevCart[userCartIndex];
        const items = [...userCart.items]; // Copy the items array
    
        if (type === 'add') {
            let itemExists = false;
    
            items.forEach(item => {
                if (item.id === productID) {
                    item.quantity += 1;
                    itemExists = true;
                }
            });
    
            if (!itemExists) {
                const newItem = { id: productID, quantity: 1 };
                items.push(newItem);
            }
        } else if (type === 'remove') {
            const updatedItems = items.map(item => {
                if (item.id === productID) {
                    if (item.quantity > 1) {
                        return { ...item, quantity: item.quantity - 1 };
                    }
                    return null;
                }
                return item;
            }).filter(item => item !== null);
    
            // Update the cart state
            startTransition(() => {
                setUserData(prev => {
                    const updatedCart = prev.cart.map(cart =>
                        cart.userID === switchedUserID ? { ...cart, items: updatedItems } : cart
                    );
                    return { ...prev, cart: updatedCart };
                });
            });
    
            return;
        }
    
        // Update the cart state for 'add' type
        startTransition(() => {
            setUserData(prev => {
                const updatedCart = prev.cart.map(cart =>
                    cart.userID === switchedUserID ? { ...cart, items: items } : cart
                );
                return { ...prev, cart: updatedCart };
            });
        });
    }
    
    function handleDiscount(discountPercent) {
        setOrderData(prev => {
            // Calculate the discount amount
            const discountAmount = (discountPercent / 100) * prev.subTotal;
    
            // Create a new discount object
            const newDiscounts = {
                percent: discountPercent,
                amount: discountAmount
            };
    
            // Calculate the new total amount based on the new discount
            const newTotalAmount = calculateTotalAmount(prev.subTotal, newDiscounts, prev.taxes);

            // Update the state with the new discounts and total amount
            return {
                ...prev,
                discounts: newDiscounts,
                totalAmount: newTotalAmount
            };
        });
    }

    function handleTaxes(taxPercent, type) {
        const validTaxPercent = Math.max(0, taxPercent);
    
        setOrderData(prev => {
            const taxAmount = (validTaxPercent / 100) * prev.subTotal;
            const newTaxes = { ...prev.taxes, [type]: { percent: validTaxPercent, amount: taxAmount } };
            const newTotalAmount = calculateTotalAmount(prev.subTotal, prev.discounts, newTaxes);
            return { ...prev, taxes: newTaxes, totalAmount: newTotalAmount };
        });
    }

    function calculateTotalAmount(subTotal, discounts, taxes) {
        // Ensure subTotal is non-negative
        const validSubTotal = Math.max(0, subTotal);
        const totalDiscount = (discounts.percent / 100) * validSubTotal;
    
        const totalTaxes = Object.values(taxes).reduce((acc, tax) => acc + (tax.amount || 0), 0);
        
        const totalAmount = validSubTotal - totalDiscount + totalTaxes;
        return totalAmount;
    }
    
    async function handlePlaceOrder() {
        const response = await AxioRequests.HandlePostRequest({
            data: { reqUserID: userData?.switchedUser?.userID,  orderData: orderData },
            route: ROUTES.postCheckoutRoute,
            type: 'post',
            toastDescription: 'Order has placed successfully!'
        });
        if (response?.status === 200) {
            navigate('../bills')
            startTransition(() => {
                setUserData(prev => ({ ...prev, cart: response.data }))
            })

        }
    }

    async function handleCartItemQuantity(productID, quantity, isDeleteEnabled = false) {
        const switchedUserID = userData?.switchedUser?.userID;
        const prevCart = [...(userData?.cart?.length > 0 ? userData.cart : [])];
    
        // Find the user's cart
        const userCartIndex = prevCart.findIndex(cart => cart.userID === switchedUserID);
        // If user cart doesn't exist, do nothing (quantity update should only work if the cart already exists)
        if (userCartIndex === -1) {
            return;
        }
    
        const userCart = prevCart[userCartIndex];
        const items = [...(userCart.items ? userCart.items : [])];
    
        let updatedItems = items;
        if(isDeleteEnabled && quantity === 0){
            updatedItems = items.filter(item => item.id !== productID)
        } else {
            updatedItems = items.map(item => {
                if (item.id === productID) {
                    return { ...item, quantity };
                }
                return item;
            });
        }
    
        // Update the cart state with the new quantity
        startTransition(() => {
            setUserData(prev => {
                const updatedCart = prev.cart.map(cart =>
                    cart.userID === switchedUserID ? { ...cart, items: updatedItems } : cart
                );
                return { ...prev, cart: updatedCart };
            });
        });
    }
    
    async function handleCartItemPrice(refProductID, newPrice) {
        let tempSubTotal = 0;
        const updatedProductList = orderData.products.map(product => {
            if (product.productID === refProductID) {
                tempSubTotal += newPrice * product.productQuantity;
                return { ...product, productPrice: newPrice };
            }
            tempSubTotal += product.productPrice * product.productQuantity;
            return product;
        });
    
        // Recalculate taxes and total amount
        const updatedTaxes = calculateTotalTaxes(tempSubTotal);
        const newTotalAmount = calculateTotalAmount(tempSubTotal, orderData.discounts, updatedTaxes);
    
        // Use startTransition to update the state
        startTransition(() => {
            setOrderData(prev => ({
                ...prev,
                subTotal: tempSubTotal,
                products: updatedProductList,
                taxes: updatedTaxes,
                totalAmount: newTotalAmount
            }));
        });
    }
    
    const calculateTotalTaxes = (subTotal) => {
        const IGST = subTotal * (orderData.taxes.IGST.percent / 100);
        const CGST = subTotal * (orderData.taxes.CGST.percent / 100);
        const SGST = subTotal * (orderData.taxes.SGST.percent / 100);
        
        const totalTaxes = IGST + CGST + SGST;
    
        return {
            IGST: { ...orderData.taxes.IGST, amount: IGST },
            CGST: { ...orderData.taxes.CGST, amount: CGST },
            SGST: { ...orderData.taxes.SGST, amount: SGST },
            total: totalTaxes
        };
    };
    

    function handleTempProductQty(productID, quantity){
        setTempProductQty(prev => ({...prev, [productID]: quantity}))
    }

    function handleTempProductPrice(productID, price){
        setTempProductPrice(prev => ({...prev, [productID]: price}))
    }

    if (isPending) {
        return <Loader />
    }
    return {
        userData,
        orderData,
        handleCart,
        handleDeleteProduct,
        handleDiscount,
        handleTaxes,
        handlePlaceOrder,
        handleCartItemQuantity,
        handleCartItemPrice,
        tempProductQty,
        tempProductPrice,
        handleTempProductQty,
        handleTempProductPrice
    }

}

export default useCheckout;