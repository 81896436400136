import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { startTransition, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import { FireToast } from '../../../context/toastContext.js';
import AxioRequests from '../../../functions/axiosRequests.js';
import ROUTES from '../../../util/routes.js';
import VALIDATION from '../../../validation/auth.js';
import Loader from '../../loader.js';

const LendingRegister = ({ id, handleConfirmation, showDetails, editingSelf = false }) => {
    const [loading, setLoading] = useState(true);
    const [defaultValues, setDefaultValues] = useState({
        reqUserID: id,
        userName: '',
        userMobile: '',
        userAddress: 'NA',
        sonof: '',
        isEdit: id !== '',
    })

    useEffect(() => {
        if (loading && id !== '') handleGetRequest()
    }, [loading])

    // New Routes for Backend
    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.lendingCommonUserDetailRoute}/${id}`);
        if (response.status === 200) {
            startTransition(() => {
                setLoading(false)
                setDefaultValues(prev => ({ ...prev, ...response.data }))
            })

        }
    }

    if (loading && id !== '') {
        return <Loader message='Loading..' />;
    }

    return (
        <Formik
            initialValues={defaultValues}
            enableReinitialize
            validationSchema={VALIDATION.lendingRegisterFormValidation}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const response = await AxioRequests.HandlePostRequest({
                        route: ROUTES.lendingCommonUserDetailRoute, // Define your registration route
                        type: id === '' ? 'post' : 'put',
                        data: values,
                        toastDescription: `User ${id === '' ? 'has registered' : 'profile has updated'} successfully`
                    });
                    if (response?.status === 200) {
                        handleConfirmation();
                    }
                } catch (err) {
                    console.log(err);
                    FireToast({ title: 'Error', subTitle: err.response?.data?.message || 'Registration failed' });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, touched, dirty, isValid, values, setFieldValue }) => {
                return (
                    <Form className='row rounded border shadow-lg p-4 m-2'>
                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="userName">Name</FormLabel>
                            <Field
                                name="userName"
                                type="text"
                                placeholder='John Doe'
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.userName && touched.userName)}
                            />
                            <ErrorMessage name="userName" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="userMobile">Mobile</FormLabel>
                            <Field
                                name="userMobile"
                                type="number"
                                placeholder="9854XXXXXX"
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.userMobile && touched.userMobile)}
                            />
                            <ErrorMessage name="userMobile" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12'>
                            <FormLabel htmlFor="userAddress">Address</FormLabel>
                            <Field
                                name="userAddress"
                                as="textarea"
                                placeholder="Address.."
                                disabled={showDetails}
                                rows={3}
                                className={`form-control ${errors.adminReason && touched.adminReason ? 'is-invalid' : ''}`}
                            />
                            <ErrorMessage name="userAddress" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="sonof">Father's Name</FormLabel>
                            <Field
                                name="sonof"
                                type="text"
                                placeholder="Mr. KG Verma"
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.sonof && touched.sonof)}
                            />
                            <ErrorMessage name="sonof" component="div" className="invalid-feedback" />
                        </FormGroup>

                        {!showDetails &&
                            <div className='col-12 my-2 my-md-4'>
                                <Button type="submit" variant="primary" className='mx-auto d-block' disabled={!(dirty && isValid)}>
                                    {id === '' ? 'Register User' : 'Update Details'}
                                </Button>
                            </div>
                        }
                    </Form>
                )
            }}
        </Formik>
    );
};

export default LendingRegister;
