import React, { startTransition, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEdit, FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useAuthContext } from "../../../context/authContext";
import ExportButton from "../../../functions/exportToExcel";
import useShoppingPanel from "../../../hooks/billing/useProductList";
import ROUTES from "../../../util/routes";
import ModalWrapper from "../../modalWrapper";
import ProductForm from "./product";
import useCheckout from "../../../hooks/billing/useCheckout";
import { FireToast } from "../../../context/toastContext";

const ShoppingPanel = () => {
  const {
    productList,
    handleProductDeletion,
    handleCart,
    handleGoToCart,
    tempPrductCartQty,
    handleTempCartQty,
  } = useShoppingPanel();
  const { userData, handleCartItemQuantity } = useCheckout();

  const [isAdminProfile, setIsAdminProfile] = useState(false);
  const [filteredProductList, setFilteredProductList] = useState(productList);
  const [searchFilter, setSearchFilter] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const [modalData, setModalData] = useState({
    isEdit: false,
    title: "Add New Product",
    id: "",
  });

  const [productToggle, setProductToggle] = useState(false);
  const [productData, setProductData] = useState({
    title: "",
    desciption: "",
    size: "",
    sku: "",
    price: "",
    quantity: 0,
  });

  useEffect(() => {
    let tempAdminValue =
      userData?.isAdmin && userData?.switchedUser?.userID ? false : true;
    startTransition(() => {
      setIsAdminProfile(tempAdminValue);
    });
  }, [userData]);

  useEffect(() => {
    if (productList?.length > 0) {
      let tempList = productList;
      if (searchFilter !== "") {
        tempList = tempList.filter((product) =>
          product.productName.toLowerCase().includes(searchFilter.toLowerCase())
        );
      }

      setFilteredProductList(tempList);
    }
  }, [productList, searchFilter]);
  return (
    <>
      <div className="d-flex justify-content-between gap-2 align-items-center my-2 my-md-4">
        <div className="d-flex gap-2">
          {isAdminProfile && (
            <>
              <Button
                type="button"
                className="w-auto"
                onClick={() => {
                  setModalData({
                    isEdit: false,
                    title: "Add New Product",
                    id: "",
                  });
                  setModalToggle(true);
                }}
              >
                Add New Product
              </Button>
              <ExportButton
                data={productList}
                fileName="product-list"
                className="max-content"
              />
            </>
          )}
          {userData?.cart?.some(
            (userCart) =>
              userCart.userID === userData?.switchedUser?.userID &&
              userCart.items.length > 0
          ) && (
            <Button type="button" className="w-auto" onClick={handleGoToCart}>
              Go To Cart
            </Button>
          )}
        </div>
      </div>
      <div className="my-2 my-md-4">
        <input
          type="text"
          name="searchUser"
          id="searchUser"
          placeholder="Search Product by Name"
          className="form-control rounded-2"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        />
      </div>
      <div className="row">
        {filteredProductList?.length > 0 ? (
          filteredProductList.map((product, index) => {
            const userCart = userData?.cart?.find(
              (user) => user.userID === userData?.switchedUser?.userID
            );
            const hasIteminCart = userCart?.items?.some(
              (item) => item.id === product.productID
            );
            const itemQuantity = hasIteminCart
              ? userCart?.items?.find((item) => item.id === product.productID)
                  ?.quantity
              : 0;
            const productImage =
              product.productImage === ""
                ? `https://picsum.photos/200/300?random=${index + 1}`
                : `${ROUTES.IMAGE_URL}/${product.productImage}`;
            return (
              <div
                key={product.productID}
                className="col-12 col-md-4 col-lg-3 mb-3"
              >
                <div className="product-wrapper rounded p-3 position-relative">
                  <div className="position-absolute w-100 top-0 start-0 end-0 d-flex justify-content-between">
                    <div className="bg-light text-body-background fw-bold fs-6 max-height rounded px-2">
                      Available Quantites: {product.productQuantity}
                    </div>
                    {isAdminProfile && (
                      <div className="mt-2 me-2 d-flex gap-2 align-items-center w-25">
                        <OverlayTrigger
                          placement="top"
                          className="h-auto"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              Edit Product
                            </Tooltip>
                          )}
                        >
                          <Button
                            variant="warning"
                            size="sm"
                            className="bg-body-background"
                            onClick={() => {
                              setModalData({
                                isEdit: true,
                                title: "Edit Product's Details",
                                id: product.productID,
                              });
                              setModalToggle(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          className="h-auto"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="Delete-User" {...props}>
                              Delete User
                            </Tooltip>
                          )}
                        >
                          <Button
                            variant="danger"
                            type="button"
                            size="sm"
                            className="fw-bold bg-body-background"
                            onClick={() =>
                              handleProductDeletion(product.productID)
                            }
                          >
                            <FaTrashAlt />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>

                  <img
                    src={productImage}
                    alt={`${product.productName}s Image`}
                  />
                  <div className="fs-4 truncate-2-lines">
                    {product.productName}
                  </div>
                  <small className="text-light-grey text-truncate">
                    {product.productSKU}
                  </small>
                  <div className="truncate-3-lines text-light-grey position-relative">
                    {product.productDescription}
                    <div
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        padding: "0.5rem",
                        borderRadius: "15px",
                        fontWeight: 500,
                      }}
                      className="position-absolute end-0 bottom-0 px-2 cursor-pointer"
                      onClick={() => {
                        setProductToggle(!productToggle);
                        setProductData({
                          desciption: product.productDescription,
                          price: product.productPrice,
                          quantity: product.productQuantity,
                          size: product.productSize,
                          sku: product.productSKU,
                          title: product.productName,
                        });
                      }}
                    >
                      Read More...
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="text-light-grey">
                      Buying Price:{" "}
                      <b className="text-light">₹{product.productPrice}</b>
                    </div>
                    <div className="text-light-grey">
                      Selling Price:{" "}
                      <b className="text-light">₹{product.sellingPrice}</b>
                    </div>
                  </div>
                </div>
                <div className="p-2 bg-body-background shadow-lg">
                  {isAdminProfile === false && (
                    <>
                      {product?.productQuantity > 0 ||
                      userData.switchedUser?.userRole === "Seller" ? (
                        hasIteminCart ? (
                          <div className="cart-adding-button-wrapper">
                            <Button
                              size="sm"
                              type="button"
                              className=""
                              onClick={() =>
                                handleCart(product.productID, "remove")
                              }
                            >
                              <FaMinus />
                            </Button>
                            <input
                              type="text"
                              value={
                                tempPrductCartQty?.[product.productID] ?? 0
                              }
                              className="form-control w-25"
                              onChange={(e) => {
                                const value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                const numericValue = Number(value);

                                if (
                                  numericValue <= product.productQuantity ||
                                  userData.switchedUser?.userRole === "Seller"
                                ) {
                                  handleTempCartQty(
                                    product.productID,
                                    numericValue
                                  );
                                } else if (
                                  numericValue > product.productQuantity
                                ) {
                                  FireToast({
                                    title: "Error",
                                    subTitle: `Maximum allowed quantity is ${product.productQuantity}`,
                                  });
                                }
                              }}
                              onBlur={() =>
                                handleCartItemQuantity(
                                  product.productID,
                                  tempPrductCartQty?.[product.productID],
                                  false
                                )
                              }
                            />
                            <Button
                              size="sm"
                              type="button"
                              onClick={() => {
                                if (
                                  itemQuantity < product.productQuantity ||
                                  userData.switchedUser?.userRole === "Seller"
                                ) {
                                  handleCart(product.productID, "add");
                                }
                              }}
                            >
                              <FaPlus />
                            </Button>
                          </div>
                        ) : (
                          <Button
                            type="button"
                            onClick={() => handleCart(product.productID, "add")}
                          >
                            Add to Card
                          </Button>
                        )
                      ) : (
                        <Button type="button" disabled={true}>
                          Out of Stock
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-light">No Product Found</div>
        )}
      </div>
      {modalToggle === true ? (
        <ModalWrapper
          title={modalData.title}
          toggle={modalToggle}
          setToggle={setModalToggle}
        >
          <ProductForm
            id={modalData.id}
            editMode={modalData.isEdit}
            handleConfirmation={() => {
              setModalToggle(!modalToggle);
              window.location.reload();
            }}
          />
        </ModalWrapper>
      ) : (
        <></>
      )}
      {productToggle === true ? (
        <ModalWrapper
          title={"Product Details"}
          toggle={productToggle}
          setToggle={setProductToggle}
          hasBackground={false}
          size={"sm"}
        >
          <div className="text-light p-2">
            <div className="fw-bold fs-4">{productData.title}</div>
            <small className="fs-6">{productData.desciption}</small>
            <div className="w-100 d-flex my-2">
              <div className="w-50">Product Size</div>
              <div className="w-50">
                <small className="fs-6">: {productData.size}</small>
              </div>
            </div>
            <div className="w-100 d-flex my-2">
              <div className="w-50">Product Quantity</div>
              <div className="w-50">
                <small className="fs-6">: {productData.quantity}</small>
              </div>
            </div>
          </div>
        </ModalWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default ShoppingPanel;
