import * as Yup from 'yup';


const loginFormValidation = Yup.object().shape({
    userEmail: Yup.string()
    .email('Invalid Email Address')
    .test('is-email-required', 'Required Field', function(value) {
        const { loginType } = this.parent;
        if (loginType === 'email') {
            return !!value;
        }
        return true;
    }),
    userPhone: Yup.string()
        .length(10, 'Invalid Number')
        .matches(/^[0-9]+$/, 'Phone number must contain only digits')
        .test('is-phone-required', 'Required Field', function(value) {
            const { loginType } = this.parent;
            if (loginType === 'phone') {
                return !!value;
            }
            return true;
    }),
    userPassword: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
            'Password must contain at least 8 characters, one uppercase, one lowercase, one number',
        ),
});
const registerFormValidation = Yup.object().shape({
    userName: Yup.string()
        .required('Name is required')
        .min(2, 'Name must be at least 2 characters long')
        .max(50, 'Name must not exceed 50 characters'),

    userMobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[0-9]+$/, 'Mobile number must be numeric')
        .min(10, 'Mobile number must be at least 10 digits long')
        .max(10, 'Mobile number must not exceed 10 digits'),

    userAddress: Yup.string()
        .min(5, 'Address must be at least 5 characters long')
        .max(500, 'Address must not exceed 500 characters').optional(),

    userEmail: Yup.string()
        .email('Invalid Email Address')
        .required('Email is required'),

    userPassword: Yup.string().test('is-required', 'Password is required', function (value) {
        const { isEdit } = this.parent;
        if (isEdit) return true;
        return !!value;
    }).min(8, 'Password must be at least 8 characters long')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
            'Password must contain at least one uppercase letter, one lowercase letter, and one number'
        ),
    userGST: Yup.string()
        .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Invalid GST number format")
        .length(15, 'GST number must be exactly 15 characters').optional(),
    businessSign: Yup.string()
        .optional()
        .max(5, 'Business sign must not exceed 5 characters'),
    userType: Yup.string().notOneOf([''], 'Please select an valid option').required('Required Field!')
});

// Lending Validation
const lendingRegisterFormValidation = Yup.object().shape({
    userName: Yup.string()
        .required('Name is required')
        .min(2, 'Name must be at least 2 characters long')
        .max(50, 'Name must not exceed 50 characters'),

    userMobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[0-9]+$/, 'Mobile number must be numeric')
        .min(10, 'Mobile number must be at least 10 digits long')
        .max(10, 'Mobile number must not exceed 10 digits'),

    userAddress: Yup.string()
        .min(0, 'Address must be at least 0 characters long')
        .max(500, 'Address must not exceed 500 characters').optional(),
    sonof:Yup.string()
    .required('Name is required')
    .min(2, 'Name must be at least 2 characters long')
    .max(50, 'Name must not exceed 50 characters'),
});

const partnerFormValidation = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .min(2, 'Name must be at least 2 characters long')
        .max(50, 'Name must not exceed 50 characters'),
    mobile:  Yup.string()
        .required('Mobile number is required')
        .matches(/^[0-9]+$/, 'Mobile number must be numeric')
        .min(10, 'Mobile number must be at least 10 digits long')
        .max(10, 'Mobile number must not exceed 10 digits'),
})



const VALIDATION = { loginFormValidation, registerFormValidation, lendingRegisterFormValidation, partnerFormValidation }

export default VALIDATION