// src/components/Login.js

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState, useTransition } from "react";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext.js";
import { FireToast } from "../../context/toastContext.js";
import axiosRequests from "../../functions/axiosRequests.js";
import ROUTES from "../../util/routes.js";
import VALIDATION from "../../validation/auth.js";
import Loader from "../loader.js";

const LoginType = [
  {
    id: 1,
    typeName: "By Email",
    slug: "email",
  },
  {
    id: 2,
    typeName: "By Phone",
    slug: "phone",
  },
];

const Login = () => {
  const [isPending, startTransition] = useTransition();
  const { userID, setUserID, setUserData } = useAuthContext();
  const [activeLoginType, setActiveLoginType] = useState(LoginType[1].slug);
  const [landingUser, setLendingUser] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    userID !== "" && navigate("../bills");
  }, [userID, navigate]);

  if (isPending) {
    return <Loader message="Loading.." />;
  }
  return (
    <div className="row h-100">
      <div className="col-lg-5 col-md-6 col-12 mx-auto my-auto d-block position-relative">
        <div className="background">
          <div className="shape"></div>
          <div className="shape"></div>
        </div>
        <Formik
          initialValues={{
            userEmail: "",
            userPhone: "",
            userPassword: "",
            loginType: activeLoginType,
          }}
          enableReinitialize
          validationSchema={VALIDATION.loginFormValidation}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const response = await axiosRequests.HandlePostRequest({
                route: landingUser
                  ? ROUTES.lendingPostLoginRoute
                  : ROUTES.postLoginRoute,
                type: "post",
                data: values,
                toastDescription: "User has logged-in successfully",
              });
              if (response?.status === 200) {
                const {
                  isAdmin,
                  userEmail,
                  userID,
                  userName,
                  userRole,
                  cart,
                  isLendingUser,
                } = response?.data?.data;
                const userDetails = {
                  userID: userID,
                  userName: userName,
                  userEmail: userEmail,
                  isAdmin: isAdmin,
                  userRole: userRole,
                  isLendingUser: isLendingUser,
                  cart: cart,
                };
                startTransition(() => {
                  setUserID(userID);
                  setUserData(userDetails);
                });

                let redirectionRoute = isAdmin
                  ? "../vertical-selection"
                  : isLendingUser
                  ? "../lending/bills"
                  : "../bills";
                navigate(redirectionRoute);
              }
            } catch (err) {
              console.log(err);
              FireToast({});
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, dirty, isValid }) => (
            <Form className="w-75 mx-auto rounded border shadow-lg p-4 d-flex flex-column gap-4">
              <div className="h2 text-center">Login Here</div>
              <div className="d-flex gap-2 text-center">
                {LoginType?.map((type) => (
                  <div
                    key={type.id}
                    className={`cursor-pointer w-50 rounded fs-5 ${
                      activeLoginType === type.slug
                        ? "bg-light text-body-background fw-bold"
                        : "border-light text-light border-2"
                    }`}
                    onClick={() => setActiveLoginType(type.slug)}
                  >
                    {type.typeName}
                  </div>
                ))}
              </div>
              {activeLoginType === LoginType[0].slug ? (
                <FormGroup>
                  <FormLabel htmlFor="userEmail">Email Address</FormLabel>
                  <Field
                    name="userEmail"
                    type="email"
                    placeholder="user@example.com"
                    as={FormControl}
                    isInvalid={!!(errors.userEmail && touched.userEmail)}
                  />
                  <ErrorMessage
                    name="userEmail"
                    component="div"
                    className="invalid-feedback"
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <FormLabel htmlFor="userPhone">Phone Number</FormLabel>
                  <Field
                    name="userPhone"
                    type="number"
                    placeholder="989XXXXXXX"
                    as={FormControl}
                    isInvalid={!!(errors.userPhone && touched.userPhone)}
                  />
                  <ErrorMessage
                    name="userPhone"
                    component="div"
                    className="invalid-feedback"
                  />
                </FormGroup>
              )}

              <FormGroup>
                <FormLabel htmlFor="userPassword">Password</FormLabel>
                <Field
                  name="userPassword"
                  type="password"
                  placeholder="******"
                  as={FormControl}
                  isInvalid={!!(errors.userPassword && touched.userPassword)}
                />
                <ErrorMessage
                  name="userPassword"
                  component="div"
                  className="invalid-feedback"
                />
              </FormGroup>

              <div>
                <small
                  style={{
                    padding: "1rem",
                    background: "yellow",
                    borderRadius: "15px",
                    color: "black",
                    fontWeight: 500,
                    margin: "10px",
                  }}
                  className="ms-auto d-block cursor-pointer max-content"
                  onClick={() => setLendingUser(!landingUser)}
                >
                  {landingUser ? "Admin Login" : "Client Login"} ?
                </small>
                <Button type="submit" disabled={!(dirty && isValid)}>
                  Sign-In
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
