import React, { useEffect, useState, startTransition } from 'react'
import { useAuthContext } from '../../../context/authContext'
import useMyOrder from '../../../hooks/billing/useMyOrder'
import BillList from './transactionList'
import Loader from '../../loader'
import { Button } from 'react-bootstrap'
import CONSTANT from '../../../util/constant';
import ExportButton from '../../../functions/exportToExcel'

const {userType} = CONSTANT;

const AllBills = () => {
    const { userID } = useAuthContext();
    const { memberData, handlePayment, isPending, loading, totalPendencies, handleOrderDelete } = useMyOrder({userID:userID, isAdmin:true});
    
    const [billList, setBillList] = useState(memberData);
    const [activeType, setActiveType] = useState(userType[0].slug)

    useEffect(() => {
        if(memberData?.billsList?.length > 0){
            let tempList = memberData.billsList;

            if(activeType === userType[1].slug){
                tempList = tempList?.filter(user => user.userRole === userType[1].slug)
            } else if (activeType === userType[2].slug){
                tempList = tempList?.filter(user => user.userRole === userType[2].slug)
            }

            startTransition(() => {
                setBillList(tempList)
            })
        }
    }, [memberData?.billsList, activeType])


    if (isPending) {
        return <Loader />
    }

    if(loading?.initial){
        return <Loader />
    }

    return (
        <>
            <div className='d-flex justify-content-between gap-2 align-items-center mt-2 mt-md-4'>
                <div className='d-flex w-100 align-items-center justify-content-between my-2 my-md-4'>
                    <div className='d-flex gap-2'>
                        {
                            userType?.map(type => (
                                <Button type='button' key={type.id} className={`w-auto fw-bold ${activeType === type.slug ? 'bg-light text-body-background' : ''}`} onClick={() => setActiveType(type.slug)}>
                                    {type.typeName}
                                </Button>
                            ))
                        }
                        <ExportButton data={billList} fileName='bill-list' className='max-content' />
                    </div>
                </div>
            </div>
            <BillList data={billList} handlePayment={handlePayment} handleDelete={(orderID) => handleOrderDelete(orderID)} />
        </>
    )
}

export default AllBills