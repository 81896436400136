import * as Yup from 'yup';


const productFormValidation = Yup.object().shape({
    productSKU: Yup.string().min(5, "Minimum 5 Characters are required").max(20, 'Max 20 Characters are allowed!').required('Required Field!'),
    productName: Yup.string().min(3, "Minimum 3 Characters are required").max(200, 'Max 200 Characters are allowed!').required('Required Field!'),
    productDescription: Yup.string().min(20, "Minimum 20 Characters are required").max(500, 'Max 500 Characters are allowed!').optional(),
    productPrice: Yup.number().optional(),
    sellingPrice: Yup.number().optional()
        .when('productPrice', (productPrice, schema) => 
            schema.min(productPrice, 'Selling price should be higher than buying price')
        ),
    isAvailable: Yup.boolean(),
    productQuantity: Yup.number().optional(),
    productSize: Yup.string().optional(),
    productImage: Yup.mixed()
        .test('fileSize', 'File size too large, Max limit is 1MB', function (value) {
            const { isEdit } = this.parent;
            if (isEdit || !value) return true;
            if (value.size) {
                return value.size <= 1048576;
            }
            return false;
        })
        .test('fileType', 'Invalid file type. Only PNG, JPG, and WEBP allowed', function (value) {
            const { isEdit } = this.parent;
            if (isEdit || !value) return true;
            if (value.type) {
                return value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'image/webp';
            }
            return false;
        })
        .optional(),
});

const lendingProductFormValidation = Yup.object().shape({
    productName: Yup.string().min(3, "Minimum 3 Characters are required").max(200, 'Max 200 Characters are allowed!').required('Required Field!'),
    productDescription: Yup.string().min(0, "Minimum 0 Characters are required").max(500, 'Max 500 Characters are allowed!').optional(),
    lendingTerms: Yup.string().min(0, "Minimum 0 Characters are required").max(500, 'Max 500 Characters are allowed!').optional(),
    productPrice: Yup.number().required('Required Field!'),
    lendingAmount: Yup.number().required('Required Field!'),
    lendingPercent: Yup.number().min(0, "Value should be greater than 1").max(100, "Value should be lesser then 100").required('Require Field!'),
    lendigTenure: Yup.number().min(0, "Value should be greater than 1").max(100, "Value should be lesser then 100").required('Require Field!'),
    productQuantity: Yup.number().optional(),
    productWeight: Yup.string().optional(),
    productSize: Yup.string().optional(),
    productImage:  Yup.mixed()
    .test('fileSize', 'File size too large, Max limit is 1MB', function (value) {
        const { isEdit } = this.parent;
        if (isEdit || !value) return true;
        if (value.size) {
            return value.size <= 1048576;
        }
        return false;
    })
    .test('fileType', 'Invalid file type. Only PNG, JPG, and WEBP allowed', function (value) {
        const { isEdit } = this.parent;
        if (isEdit || !value) return true;
        if (value.type) {
            return value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'image/webp';
        }
        return false;
    })
    .optional(),
});

const VALIDATION = { productFormValidation, lendingProductFormValidation };

export default VALIDATION;