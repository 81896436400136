import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaEdit, FaEye, FaTrashAlt, FaUserCheck, FaUserSlash, FaFileInvoice  } from 'react-icons/fa';
import { useAuthContext } from '../../../context/authContext';
import useUserDetails from '../../../hooks/billing/useUserDetail';
import ModalWrapper from '../../modalWrapper';
import Register from '../../auth/register'
import usePagination from '../../../hooks/usePagination';
import ExportButton from '../../../functions/exportToExcel';

const userType = [
    {
        id: 1,
        typeName: 'All',
        slug: 'all'
    },
    {
        id: 2,
        typeName: 'Client',
        slug: 'Client'
    },
    {
        id: 3,
        typeName: 'Seller',
        slug: 'Seller'
    },
]



const UserDetails = () => {
    const { userID, userData } = useAuthContext();
    const { userList, handleUserStatus, handleUserDelete, handleSwitchingUser } = useUserDetails();

    const [filteredUserList, setFilteredUserList] = useState(userList);
    const {PaginationComponent, currentList} = usePagination(filteredUserList);
    const [searchFilter, setSearchFilter] = useState('')
    const [activeType, setActiveType] = useState(userType[0].slug)
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState({
        show: false,
        title: 'Add New User',
        id: '',
    })

    useEffect(() => {
        if (userList?.length > 0) {
            let tempList = userList;
            if (searchFilter !== '') {
                tempList = tempList.filter(user => user.userName.toLowerCase().includes(searchFilter.toLowerCase()))
            }

            if (activeType === userType[1].slug) {
                tempList = tempList.filter(user => user.userRole === userType[1].slug)
            }else if(activeType === userType[2].slug){
                tempList = tempList.filter(user => user.userRole === userType[2].slug)
            }

            setFilteredUserList(tempList)
        }
    }, [userList, searchFilter, activeType])

    return (
        <>
            <div className='d-flex justify-content-between gap-2 align-items-center my-2 my-md-4'>
                {userData !== null && userData?.isAdmin && <Button type='button' className='w-auto' onClick={() => { setModalData({ show: false, title: 'Add New User', id: '' }); setModalToggle(true) }}>Add New Member</Button>}
            </div>
            <div className='d-flex gap-2 align-items-center justify-content-between'>
                <input style={{width:'auto'}} type='text' name='searchUser' id='searchUser' placeholder='Search User by Name' className='form-control rounded-2' value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
                <ExportButton data={userList} fileName='members-list' className='max-content' />
            </div>
            <div className='d-flex w-100 align-items-center justify-content-between my-2 my-md-4'>
                <div className='d-flex gap-2'>
                {
                    userType?.map(type => (
                        <Button type='button' key={type.id} className={`w-auto fw-bold ${activeType === type.slug ? 'bg-light text-body-background' : ''}`} onClick={() => setActiveType(type.slug)}>
                            {type.typeName}
                        </Button>
                    ))
                }
                </div>

            </div>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th className='text-center'>Interact</th>
                    </tr>
                </thead>
                <tbody>
                    {currentList?.length > 0 ? currentList.map((user, index) => (
                        <tr key={user.userID}>
                            <td>{index + 1}</td>
                            <td>
                                <div>
                                {user.userName} <br />
                                <small className='text-body-background px-2 bg-light rounded-1 fw-bold'>{user.userRole}</small>
                                </div>
                            </td>
                            <td>{user.userEmail}</td>
                            <td>{user.userPhone}</td>
                            <td>
                                <div className="d-flex gap-2 w-100 justify-content-center align-items-center">
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="button-tooltip" {...props}>
                                                View Details
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="primary" onClick={() => { setModalData({ show: true, title: "View User's Details", id: user.userID }); setModalToggle(true) }}>
                                            <FaEye />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="Edit Details" {...props}>
                                                Edit Details
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="warning" onClick={() => { setModalData({ show: false, title: "View User's Details", id: user.userID }); setModalToggle(true) }}>
                                            <FaEdit />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="View-Bills" {...props}>
                                                View Bills
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="warning" onClick={() => handleSwitchingUser(user.userName, user.userID, user.userRole)}>
                                            <FaFileInvoice  />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="Inactive-User" {...props}>
                                                {user.isActive ? 'Inactive User' : 'Active User'}
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant={user.isActive ? "secondary" : 'success'} onClick={() => {
                                            if (typeof handleUserStatus === 'function') {
                                                handleUserStatus(user.userID, !user.isActive);
                                            } else {
                                                console.error('handleUserStatus is not a function');
                                            }
                                        }}>
                                            {user.isActive ? <FaUserSlash /> : <FaUserCheck />}
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="Delete-User" {...props}>
                                                Delete User
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="danger" type='button' onClick={() => handleUserDelete(user.userID)}>
                                            <FaTrashAlt />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </td>
                        </tr>
                    )) : <tr><td>No users found</td></tr>}
                </tbody>
            </Table>
            <PaginationComponent />
            {
                modalToggle === true
                    ? <ModalWrapper title={modalData.title
                    } toggle={modalToggle} setToggle={setModalToggle} >
                        <Register id={modalData.id} editingSelf={modalData.id === userID} showDetails={modalData.show} handleConfirmation={() => {
                            window.location.reload();
                            setModalToggle(!modalToggle);
                        }} />
                    </ModalWrapper >
                    : <></>
            }

        </>
    );
};

export default UserDetails;
