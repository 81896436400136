import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaEdit, FaEye, FaFileInvoice, FaTrashAlt, FaUserCheck, FaUserSlash } from 'react-icons/fa';
import useLendingMembersList from '../../../hooks/lending/useMemberList';
import ModalWrapper from '../../modalWrapper';
import LendingRegister from './registrationForm';
import usePagination from '../../../hooks/usePagination';
import ExportButton from '../../../functions/exportToExcel';


const LendingMembers = () => {
    const { userList, handleUserStatus, handleUserDelete } = useLendingMembersList();

    const [filteredUserList, setFilteredUserList] = useState(userList);
    const {PaginationComponent, currentList} = usePagination(filteredUserList);

    const [searchFilter, setSearchFilter] = useState('')
    const [modalToggle, setModalToggle] = useState(false);
    const [modalData, setModalData] = useState({
        show: false,
        title: 'Add New User',
        id: '',
    })

    useEffect(() => {
        if (userList?.length > 0) {
            let tempList = userList;
            if (searchFilter !== '') {
                tempList = tempList.filter(user => user.userName.toLowerCase().includes(searchFilter.toLowerCase()))
            }

            setFilteredUserList(tempList)
        }
    }, [userList, searchFilter])


    function handleModalForm(showDetail, modalTitle, userID) {
        setModalData({ show: showDetail, title: modalTitle, id: userID }); 
        setModalToggle(true)
    }


    return (
        <>
            <div className='d-flex justify-content-between gap-2 align-items-center my-2 mt-md-4'>
                <Button type='button' className='w-auto' onClick={() => handleModalForm(false, 'Add New User', '')}>Add New Member</Button>
            </div>
            <div className='my-2 my-md-4 d-flex gap-2 align-items-center justify-content-between'>
                <input style={{width:'auto'}} type='text' name='searchUser' id='searchUser' placeholder='Search Members by Name' className='form-control rounded-2' value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
                <ExportButton data={userList} fileName='members-list' className='max-content' />
            </div>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Father's Name</th>
                        <th>Mobile</th>
                        <th className='text-center'>Interact</th>
                    </tr>
                </thead>
                <tbody>
                    {currentList?.length > 0 ? currentList.map((user, index) => (
                        <tr key={user.userID}>
                            <td>{index + 1}</td>
                            <td>{user.userName}</td>
                            <td>{user.sonof}</td>
                            <td>{user.userPhone}</td>
                            <td>
                                <div className="d-flex gap-2 w-100 justify-content-center align-items-center">
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="button-tooltip" {...props}>
                                                View Details
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="primary" onClick={() => handleModalForm(true, "View User's Details", user.userID)}>
                                            <FaEye />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="Edit Details" {...props}>
                                                Edit Details
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="warning" onClick={() =>  handleModalForm(false, "Edit User's Details", user.userID)}>
                                            <FaEdit />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="Inactive-User" {...props}>
                                                {user.isActive ? 'Inactive User' : 'Active User'}
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant={user.isActive ? "secondary" : 'success'} onClick={() => {
                                            if (typeof handleUserStatus === 'function') {
                                                handleUserStatus(user.userID, !user.isActive);
                                            } else {
                                                console.error('handleUserStatus is not a function');
                                            }
                                        }}>
                                            {user.isActive ? <FaUserSlash /> : <FaUserCheck />}
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        className='h-auto'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => (
                                            <Tooltip id="Delete-User" {...props}>
                                                Delete User
                                            </Tooltip>
                                        )}
                                    >
                                        <Button variant="danger" type='button' onClick={() => handleUserDelete(user.userID)}>
                                            <FaTrashAlt />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </td>
                        </tr>
                    )) : <tr><td>No users found</td></tr>}
                </tbody>
            </Table>
            <PaginationComponent />
            {modalToggle ? 
                <ModalWrapper title={modalData.title} toggle={modalToggle} setToggle={setModalToggle} >
                    <LendingRegister id={modalData.id} showDetails={modalData.show} handleConfirmation={() => {
                        window.location.reload();
                        setModalToggle(!modalToggle);
                    }} />
                </ModalWrapper >
            : <></>}
        </>
    );
};

export default LendingMembers;
