import React from "react";
import { Button, Container, Dropdown, DropdownButton } from "react-bootstrap";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login from "./component/auth/login";
import Home from "./component/home";
import { useAuthContext } from "./context/authContext";
import AxioRequests from "./functions/axiosRequests";
import ContentRoute from "./routes/contentRoute";
import ROUTES from "./util/routes";

const App = () => {
  const { userID, userData, setUserData } = useAuthContext();
  const { finalRoutes } = ContentRoute();

  const handleLogout = async () => {
    try {
      const response = await AxioRequests.HandleGetRequest(
        ROUTES.postLogoutRoute
      );
      if (response.status === 200) {
        window.location.href = "/";
        sessionStorage.setItem("userID", "");
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <Router>
      <div className="vh-100 d-flex flex-column">
        <nav className="navbar navbar-expand-lg px-2 px-md-5 border border-light">
          <Link
            to="/"
            className="navbar-brand"
            style={{
              background: "yellow",
              padding: "1rem",
              margin: "10px",
              borderRadius: "15px",
              fontWeight: "700",
              color: "black",
              fontSize: "medium",
            }}
          >
            Home
          </Link>
          <div
            className="collapse navbar-collapse"
            style={{
              display: "block",
              background: "#C45508",
              padding: "1rem",
              borderRadius: "17px",
            }}
          >
            <ul
              className="navbar-nav ms-auto"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexFlow: "center",
                alignContent: "center",
                gap: '10px'
              }}
            >
              {finalRoutes !== null &&
                finalRoutes?.length > 0 &&
                finalRoutes?.map((nav) => {
                  if (nav.isActiveRoute) {
                    return (
                      <li
                        style={{
                          background: "white",
                          borderRadius: "15px",
                          color: "white",
                        }}
                        className="nav-item"
                        key={nav.id}
                      >
                        <Link
                          to={`/${nav.route}`}
                          className="nav-link fs-6"
                          style={{
                            padding: "1rem",
                            color: "black",
                          }}
                        >
                          {nav.title}
                        </Link>
                      </li>
                    );
                  }
                })}
              {userID === "" ? (
                <li className="nav-item">
                  <Link to="/login" className="nav-link text-light fs-6">
                    Login
                  </Link>
                </li>
              ) : (
                <>
                  {userData?.isAdmin && userData?.switchedUser?.userID ? (
                    <li className="nav-item">
                      <DropdownButton
                        id="dropdown-basic-button"
                        className="px-2 py-2"
                        title={userData?.switchedUser?.userName}
                      >
                        <Dropdown.Item
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setUserData((prev) => ({
                              ...prev,
                              switchedUser: {},
                            }));
                          }}
                        >
                          Remove {userData?.switchedUser?.userName}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleLogout();
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </DropdownButton>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <div
                        className="nav-link fs-6 cursor-pointer d-flex align-items-center justify-content-center gap-1 flex-column"
                        onClick={handleLogout}
                        style={{
                          background: "red",
                          padding: "1rem",
                          margin: "10px",
                          borderRadius: "15px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        Logout
                      </div>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        </nav>
        <Container
          className="w-100 d-flex flex-column flex-fill"
          style={{ paddingBottom: "5rem" }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            {finalRoutes !== null &&
              finalRoutes.length > 0 &&
              finalRoutes?.map((route) => (
                <Route
                  key={route.id}
                  path={`/${route.route}`}
                  element={route.element}
                />
              ))}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Container>
      </div>
    </Router>
  );
};

export default App;

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-center justify-content-center my-2 my-md-4 flex-column">
      <div className="text-light my-2 my-md-4 fs-2">SS METAL</div>
      <Button
        className="w-auto px-2 px-md-4"
        onClick={() => navigate("/")}
        type="button"
      >
        Proceed to Home Page
      </Button>
    </div>
  );
};
