import React, { startTransition, useEffect, useState } from 'react';
import VerticalSelection from '../component/auth/verticalSelection';
import UserDetails from '../component/members/billing/userDetails';
import LendingMembers from '../component/members/lending/memberList';
import AllBills from '../component/order/billing/allBills';
import Checkout from '../component/order/billing/checkout';
import MyBills from '../component/order/billing/myBills';
import LendingBills from '../component/order/lending/bill';
import LendingCheckout from '../component/order/lending/checkout';
import ShoppingPanel from '../component/product/billing/productList';
import LendingProductList from '../component/product/lending/productList';
import ReportPanel from '../component/report/report';
import { useAuthContext } from '../context/authContext';
import CONSTANT from '../util/constant';
import LendingPartner from '../component/partners/lending/partnerList';

const {VerticalTypes} = CONSTANT;

const defaultRoutes = [
    {
        id: crypto.randomUUID(),
        title: 'Vertical Selection',
        route: 'vertical-selection',
        isActiveRoute: false,
        element: <VerticalSelection />
    },
]
const lendingRoutes = [
    {
        id: crypto.randomUUID(),
        title: 'Products',
        route: 'lending/products',
        isActiveRoute: true,
        element: <LendingProductList />
    },
    {
        id: crypto.randomUUID(),
        title: 'Partners',
        route: 'lending/partners',
        isActiveRoute: true,
        element: <LendingPartner />
    },
    {
        id: crypto.randomUUID(),
        title: 'Member List',
        route: 'lending/members',
        isActiveRoute: true,
        element: <LendingMembers />
    },
    {
        id: crypto.randomUUID(),
        title: 'Checkout',
        route: 'lending/checkout',
        isActiveRoute: false,
        element: <LendingCheckout />
    },
    {
        id: crypto.randomUUID(),
        title: 'Bills',
        route: 'lending/bills',
        isActiveRoute: true,
        element: <LendingBills />
    },
]
const adminRoutes = [
    {
        id: crypto.randomUUID(),
        title: 'Vertical Selection',
        route: 'vertical-selection',
        isActiveRoute: false,
        element: <VerticalSelection />
    },
    {
        id: crypto.randomUUID(),
        title: 'Products',
        route: 'products',
        isActiveRoute: true,
        element: <ShoppingPanel />
    },
    {
        id: crypto.randomUUID(),
        title: 'Product Report',
        route: 'report',
        isActiveRoute: true,
        element: <ReportPanel />
    },
    {
        id: crypto.randomUUID(),
        title: 'Member List',
        route: 'members',
        isActiveRoute: true,
        element: <UserDetails />
    },
    {
        id: crypto.randomUUID(),
        title: 'Bills',
        route: 'bills',
        isActiveRoute: true,
        element: <AllBills />
    },
]

const ContentRoute = () => {
    const {userData, userID} = useAuthContext();
    const [finalRoutes, setFinalRoutes] = useState(null);

    const switchedUserRoutes = [
        {
            id: crypto.randomUUID(),
            title: 'Products',
            route: 'products',
            isActiveRoute: true,
            element: <ShoppingPanel />
        },
        {
            id: crypto.randomUUID(),
            title: 'Bills',
            route: 'bills',
            isActiveRoute: true,
            element: <MyBills userID={userData?.switchedUser?.userID} />
        },
        {
            id: crypto.randomUUID(),
            title: 'Checkout',
            route: 'checkout',
            isActiveRoute: false,
            element: <Checkout />
        },
    ] 
    const clientRoutes = [
        {
            id: crypto.randomUUID(),
            title: 'Bills',
            route: 'bills',
            isActiveRoute: true,
            element: <MyBills userID={userID} />
        }
    ]
    const lendingClientRoutes = [
        {
            id: crypto.randomUUID(),
            title: 'Lending Bills',
            route: 'lending/bills',
            isActiveRoute: true,
            element: <LendingBills baseUserID={userID}/>
        },
    ]
    
    useEffect(() => {
        let tempNavList = [];
        if(userData?.isAdmin){
          if(userData?.verticalType === VerticalTypes[0].slug){
            if(userData?.switchedUser?.userID ){
              tempNavList = switchedUserRoutes
            } else {
              tempNavList = adminRoutes
            }
          } else if(userData?.verticalType === VerticalTypes[1].slug) {
            tempNavList = lendingRoutes
          } else tempNavList = defaultRoutes
        } else {
            tempNavList = userData?.isLendingUser === true ? lendingClientRoutes : clientRoutes;
        }
    
        startTransition(() => {
            setFinalRoutes(tempNavList)
        })
    }, [userData])
  
  return {
    adminRoutes, switchedUserRoutes, clientRoutes, lendingRoutes, finalRoutes
  };
}

export default ContentRoute

