import { startTransition, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";
import AxioRequests from "../../functions/axiosRequests";
import handleProcessedArrayData from "../../functions/processedFormData";
import ROUTES from "../../util/routes";

const useProductForm = (id = '', editMode = false) => {
    const { userID } = useAuthContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(editMode);

    useLayoutEffect(() => {
        userID === '' && navigate('../login')
    }, [userID, navigate])


    const [userList, setUserList] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [defaultValues, setDefaultValues] = useState({
        productID: id,
        reqUserID: '',
        lendingPartnerID: '',
        lendingPartnerInterest: 1.40,
        productName: '',
        productDescription: 'NA',
        productImage: '',
        productQuantity: 1, 
        productSize: 'NA',
        productWeight: 'NA',
        lendingTerms: 'NA',
        productPrice: 0,
        lendingAmount: 0,
        lendingPercent: 2,
        lendigTenure: 12,
        startDate: '',
        isEdit: editMode,
    })

    useEffect(() => {
        async function handleUserList() {
            const response = await AxioRequests.HandleGetRequest(ROUTES.lendingCommonUserDetailRoute);
            if (response?.status === 200) {
                startTransition(() => {
                    setUserList(response.data)
                })
            }
        }

        async function handlePartnerList() {
            const response = await AxioRequests.HandleGetRequest(ROUTES.lendingCommonPartnerRoute);
            if (response?.status === 200) {
                startTransition(() => {
                    setPartnerList(response.data)
                })
            }
        }

        handleUserList()
        handlePartnerList()
    }, [])

    useEffect(() => {
        if (loading) handleGetRequest()
    }, [loading])

    async function handleGetRequest() {
        // Need to update the Route for the Request
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.lendingCommonProductRoute}/${id}`);
        if (response.status === 200) {
            startTransition(() => {
                setLoading(false)
                setDefaultValues(prev => ({ ...prev, ...response.data }))
            })

        }
    }

    async function handleSubmit(values) {
        const processedData = await handleProcessedArrayData(values, ['productImage']);

        // Need to update the Route for the Request
        const response = await AxioRequests.HandlePostRequest({
            route: ROUTES.lendingCommonProductRoute,
            type: editMode ? 'put' : 'post',
            data: processedData,
            toastDescription: `Product has ${editMode ? 'updated' : 'created'} successfully`,
        });

        return response;
    }

    return {
        partnerList,
        userList,
        defaultValues,
        handleSubmit,
    }
}


export default useProductForm;