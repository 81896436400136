import React from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { FaMinus, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { FireToast } from '../../../context/toastContext'
import useCheckout from '../../../hooks/billing/useCheckout'

const Checkout = () => {
    const { orderData, handleDeleteProduct, handleCart, handleDiscount, handleTaxes, handlePlaceOrder, handleCartItemQuantity, handleCartItemPrice,  userData, handleTempProductQty, handleTempProductPrice, tempProductQty, tempProductPrice } = useCheckout();
    return (
        <>
            <div className='d-flex justify-content-between gap-2 align-items-center my-2 my-md-4'>
                <Button type='button' className='w-auto' onClick={handlePlaceOrder}>Place Order</Button>
            </div>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Product</th>
                        <th className='text-center'>Quantity</th>
                        <th className='text-center'>Price</th>
                        <th className='text-center'>Total Price</th>
                        <th className='text-center'>Interact</th>
                    </tr>
                </thead>
                <tbody>
                    {orderData?.products?.length > 0 ? (
                        orderData?.products?.map((item, index) => (
                            <tr key={`${item.productID}-${index + 1}`} className='no-blur'>
                                <td>{index + 1}</td>
                                <td>{item.productName}</td>
                                <td className='text-center'>{item.productQuantity}</td>
                                <td>
                                    <div className=''>
                                       <input 
                                            type='text'
                                            value={tempProductPrice?.[item.productID] ?? 0}
                                            className='me-auto form-control py-0 px-2 w-100'
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                value = value.replace(/[^0-9.]/g, ''); 
                                                value = value.replace(/(\..*)\./g, '$1');
                                                
                                                if (value === '' || value === '.' || !isNaN(Number(value))) {
                                                    handleTempProductPrice(item.productID, value);
                                                }
                                            }}
                                            onBlur={() => {
                                                const numericValue = Number(tempProductPrice?.[item.productID] ?? 0);
                                                handleCartItemPrice(item.productID, numericValue);
                                            }}
                                        />
  
                                    </div>
                                </td>
                                <td className='text-center'>{item.productQuantity * item.productPrice}</td>
                                <td>
                                    <div style={{width:'110%'}} className="d-flex gap-2 align-items-center justify-content-center">
                                        <OverlayTrigger
                                            placement="top"
                                            className='h-auto'
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => (
                                                <Tooltip id="Remove-Quantity" {...props}>
                                                    Remove Quantity
                                                </Tooltip>
                                            )}
                                        >
                                            <Button size='sm' type='button' onClick={() => handleCart(item.productID, 'remove')}>
                                                <FaMinus />
                                            </Button>
                                        </OverlayTrigger>
                                        <input 
                                            type='text' 
                                            value={tempProductQty?.[item.productID] ?? 0}
                                            className='me-auto form-control py-0 px-2 w-100'
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9]/g, '');
                                                const numericValue = Number(value);

                                                if (numericValue <= item.totalQuantity || userData.switchedUser?.userRole === 'Seller') {
                                                    handleTempProductQty(item.productID, numericValue)
                                                } else if (value > item.totalQuantity) {
                                                    FireToast({title: 'Error', subTitle: `Maximum allowed quantity is ${item.totalQuantity}`});
                                                }
                                            }}
                                            onBlur={() => handleCartItemQuantity(item.productID, tempProductQty[item.productID])}
                                        />
                                        <OverlayTrigger
                                            placement="top"
                                            className='h-auto'
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => (
                                                <Tooltip id="Add-Quantity" {...props}>
                                                    Add Quantity
                                                </Tooltip>
                                            )}
                                        >
                                            <Button size='sm' type='button' onClick={() => {
                                                if (item.productQuantity < item.totalQuantity || userData.switchedUser?.userRole === 'Seller') {
                                                    handleCart(item.productID, 'add')
                                                }
                                            }}>
                                                <FaPlus />
                                            </Button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="top"
                                            className='h-auto'
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props) => (
                                                <Tooltip id="Delete-User" {...props}>
                                                    Delete User
                                                </Tooltip>
                                            )}
                                        >
                                            <Button variant="danger" size='sm' type='button' onClick={() => handleDeleteProduct(item.productID)}>
                                                <FaTrashAlt />
                                            </Button>
                                        </OverlayTrigger>

                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : <tr><td>No Product in your cart</td></tr>}

                </tbody>
            </Table >
            <div className='d-flex flex-column gap-2 w-100 ms-auto text-light'>
                <div className='w-100 d-flex align-items-center'>
                    <small className='w-75'> Total Price: </small>
                    <b className='fs-5'>₹ {orderData?.subTotal}</b>
                </div>
                <div className='w-100 d-flex align-items-center'>
                    <div className='w-75 d-flex align-items-center gap-2'>
                        <small className='w-25'> Tax (IGST - %):</small>
                        <input type='number' placeholder='%' className='w-75 me-auto form-control py-0 px-2 w-auto' value={orderData?.taxes.IGST.percent} onChange={(e) => {
                            let value = e.target.value;
                            if (value < 0) value = 0;
                            if (value > 100) value = 100
                            handleTaxes(value, 'IGST')
                        }
                        } min={0} max={100} />
                    </div>
                    <b className='fs-5'>+ ₹ {orderData?.taxes.IGST.amount}</b>
                </div>
                <div className='w-100 d-flex align-items-center'>
                    <div className='w-75 d-flex align-items-center gap-2'>
                        <small className='w-25'> Tax (CGST - %):</small>
                        <input type='number' placeholder='%' className='w-75 me-auto form-control py-0 px-2 w-auto' value={orderData?.taxes.CGST.percent} onChange={(e) => {
                            let value = e.target.value;
                            if (value < 0) value = 0;
                            if (value > 100) value = 100
                            handleTaxes(value, 'CGST')
                        }
                        } min={0} max={100} />
                    </div>
                    <b className='fs-5'>+ ₹ {orderData?.taxes.CGST.amount}</b>
                </div>
                <div className='w-100 d-flex align-items-center'>
                    <div className='w-75 d-flex align-items-center gap-2'>
                        <small className='w-25'> Tax (SGST - %):</small>
                        <input type='number' placeholder='%' className='w-75 me-auto form-control py-0 px-2 w-auto' value={orderData?.taxes.SGST.percent} onChange={(e) => {
                            let value = e.target.value;
                            if (value < 0) value = 0;
                            if (value > 100) value = 100
                            handleTaxes(value, 'SGST')
                        }
                        } min={0} max={100} />
                    </div>
                    <b className='fs-5'>+ ₹ {orderData?.taxes.SGST.amount}</b>
                </div>
                <div className='w-100 d-flex align-items-center'>
                    <div className='w-75 d-flex align-items-center gap-2'>
                        <small className='w-25'> Discount (%):</small>
                        <input type='number' placeholder='%' className='w-75 me-auto form-control py-0 px-2 w-auto' value={orderData?.discounts.percent} onChange={(e) => {
                            let value = e.target.value;
                            if (value < 0) value = 0;
                            if (value > 100) value = 100
                            handleDiscount(value)
                        }
                        } min={0} max={100} />
                    </div>
                    <b className='fs-5'>- ₹ {orderData?.discounts.amount}</b>
                </div>
                <hr className='my-0' />
                <div className='w-100 d-flex align-items-center'>
                    <small className='w-75'> Total Payable Amount: </small>
                    <b className='fs-5'>₹ {orderData?.totalAmount}</b>
                </div>
                <Button type='button' className='w-auto bg-light text-dark fw-bold' onClick={handlePlaceOrder}>Place Order</Button>
            </div>
        </>
    )
}

export default Checkout