import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/authContext';
import CONSTANT from '../../util/constant';

const {VerticalTypes} = CONSTANT;


const VerticalSelection = () => {
    const { setUserData } = useAuthContext()
    const navigate = useNavigate();

    const [activeVertical, setActiveVertical] = useState('');
  return (
    <div className='row h-100'>
        <div className='col-12 col-md-6 m-auto'>
            <div className='d-flex gap-2 w-100'>
                {VerticalTypes.map(type => { 
                    return (
                        <Button key={type.id} className={`w-50 cursor-pointer d-flex flex-column align-items-center justify-content-center gap-2 border p-2 rounded ${activeVertical === type.slug ? 'bg-light text-body-background' : 'text-light bg-body-background' }`} onClick={() => setActiveVertical(type.slug)}>
                            {type.icon}
                            <div className='fs-4 fw-bold'>{type.title}</div>
                            <small>{type.subTitle}</small>
                        </Button>
                    )
                })}
            </div>

            <Button type='button' className='my-2 my-md-4 fw-bold' disabled={activeVertical === ''} onClick={() => {
                setUserData(prev => ({...prev, verticalType: activeVertical}));
                navigate('../');
            }}>Continue</Button>
        </div>
    </div>
  )
}

export default VerticalSelection