import React, { useEffect, useState, startTransition } from 'react';
import Select from 'react-select';
import useReportPanel from '../../hooks/billing/useReport';
import { Button, Table } from 'react-bootstrap';
import CONSTANT from '../../util/constant';
import ExportButton from '../../functions/exportToExcel';

const {userType} = CONSTANT;

const ReportPanel = () => {
    const {  productList, reportList, handleReport } = useReportPanel();
    const [selectedOption, setSelectedOption] = useState(null);
    const [filteredReportList, setFilteredReportList] = useState(reportList);
    const [totalValues, setTotalValues] = useState({
        quantity: 0,
        price: 0,
        totalAmount: 0,
    });
    const [activeType, setActiveType] = useState(userType[0].slug)

    useEffect(() => {
        if(reportList?.length > 0){
            let tempList = reportList;

            if(activeType === userType[1].slug) {
                tempList = tempList.filter(item => item.orderType === userType[1].subTypeName)
            } else if(activeType === userType[2].slug) {
                tempList = tempList.filter(item => item.orderType === userType[2].subTypeName)
            } 

            startTransition(() => {
                setFilteredReportList(tempList)
            })
        }
    }, [reportList, activeType])

    
    useEffect(() => {
        if(filteredReportList?.length > 0){
            let tempTotal = {
                quantity: 0,
                price: 0,
                totalAmount: 0,
            }
            filteredReportList?.forEach(item => {
                tempTotal.quantity += item.productQuantity;
                tempTotal.price += item.productPrice;
                tempTotal.totalAmount += item.totalPrice;
            })

            startTransition(() => {
                setTotalValues(prev => tempTotal)
            })
        }
    }, [filteredReportList])

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        handleReport(selectedOption.productID)
    };

    return (
        <>
            <div className='d-flex justify-content-between gap-2 align-items-center my-2 my-md-4'>
                <div className='d-flex gap-2 align-items-center'>
                <h5 style={{fontSize:'medium'}} className='text-light mb-0'> Product: </h5>
                <Select
                    value={selectedOption}
                    onChange={handleChange}
                    getOptionLabel={(option) => option.productName}
                    getOptionValue={(option) => option.productID}
                    options={productList}
                    placeholder="Select an option"
                />
                {selectedOption !== null && <ExportButton data={reportList} fileName='report-list' className='max-content' />}
                </div>
            </div>
            <div className='d-flex gap-2 ms-auto my-2'>
                {
                    userType?.map(type => (
                        <Button type='button' key={type.id} className={`w-auto fw-bold ${activeType === type.slug ? 'bg-light text-body-background' : ''}`} onClick={() => setActiveType(type.slug)}>
                            {type.subTypeName}
                        </Button>
                    ))
                }
            </div>
            <hr className='border-light my-0' />
            {selectedOption === null ? <div className='text-light my-2 my-md-4 text-center'>Select an item to view report ..</div> :
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className='max-content'>Order ID</th>
                            <th className='max-content'>Order Type</th>
                            <th className='text-center max-content'>Order Date</th>
                            <th className='text-center max-content'>Order Quantity</th>
                            <th className='text-center max-content'>Product Price</th>
                            <th className='text-center max-content'>Total Amount</th>
                            <th className='text-center max-content'>Final Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredReportList?.length > 0 ? filteredReportList?.map((item, index) => (
                            <tr key={item.orderID}  className='no-blur'>
                                <td>{index+1}</td>
                                <td><b>{item.userName}</b><br />{item.orderID}</td>
                                <td className='text-center'>{item.orderType}</td>
                                <td className='text-center'>{new Date(item.orderDate).toLocaleDateString('en-GB')}</td>
                                <td className='text-center'>{item.orderType === 'Sell' ? '- ' : '+ '} {item.productQuantity} {item.productQuantity > 0 ? "PCs" : 'PC'}</td>
                                <td className='text-center'>₹ {item?.productPrice}</td>
                                <td className='text-center'>₹ {item?.totalPrice}</td>
                                <td className='text-center'>{item.updatedProductQuantity} {item.updatedProductQuantity > 0 ? "PCs" : 'PC'}</td>
                            </tr>
                        )) : <tr><td colSpan={'5'}>No Order found!</td></tr>}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4" className='fw-bold'>Total</td>
                            <td colSpan="1" className='fw-bold text-center'>{totalValues.quantity}</td>
                            <td colSpan="1" className='fw-bold text-center'>₹ {totalValues.price}</td>
                            <td colSpan="1" className='fw-bold text-center'>₹ {totalValues.totalAmount}</td>
                            <td colSpan={'1'}></td>

                        </tr>
                    </tfoot>
                </Table>
            }         
        </>
    );
};

export default ReportPanel;
