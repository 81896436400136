import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader = ({ message = "Loading..." }) => {
    return (
        <div className="loader-container">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div className="loader-message">{message}</div>
        </div>
    );
};

export default Loader;