import React from "react";
import { useAuthContext } from "../context/authContext";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ImageUpload from "./imageUpload";

const Home = () => {
  const { userID } = useAuthContext();
  const navigate = useNavigate();

  function handleNavigate() {
    const route = userID === "" ? "../login" : "../bills";
    navigate(route);
  }
  return (
    <div className="d-flex align-items-center justify-content-center my-2 my-md-4 flex-column">
      <div
        style={{
          textTransform: "uppercase",
        }}
        className="text-light my-2 my-md-4 fs-2"
      >
        Welcome to SS Metal
      </div>
      {/* <ImageUpload /> */}
      {userID === "" ? (
        <Button
          className="w-auto px-2 px-md-4"
          onClick={handleNavigate}
          type="button"
        >
          Proceed to Login
        </Button>
      ) : (
        <span
          style={{
            color: "white",
            fontWeight: 500,
            fontSize: "larger",
            textTransform: "uppercase",
          }}
          className="w-auto px-2 px-md-4"
        >
          {" "}
          Select Any Option
        </span>
      )}
    </div>
  );
};

export default Home;
