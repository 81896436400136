import { FaFileInvoiceDollar, FaHandHoldingUsd } from 'react-icons/fa';

const userType = [
    {
        id: 1,
        typeName: 'All',
        subTypeName: 'All',
        slug: 'all'
    },
    {
        id: 2,
        typeName: 'Client',
        subTypeName: 'Sell',
        slug: 'Client'
    },
    {
        id: 3,
        typeName: 'Seller',
        subTypeName: 'Buy',
        slug: 'Seller'
    },
]

const VerticalTypes = [
    {
        id: crypto.randomUUID(),
        title: 'Billing Vertical',
        subTitle: 'Manage all billing and invoicing needs',
        slug: 'product',
        icon: <FaFileInvoiceDollar size={100} />,
    },
    {
        id: crypto.randomUUID(),
        title: 'Lending Vertical',
        subTitle: 'Handle all types of loans',
        slug: 'lending',
        icon: <FaHandHoldingUsd size={100} />,
    },
];

const transactionTypes = [
    {
        id: crypto.randomUUID(),
        typeName: 'All',
        slug: 'all',
    },
    {
        id: crypto.randomUUID(),
        typeName: 'Payment',
        slug: 'payment',
    },
    {
        id: crypto.randomUUID(),
        typeName: 'Interest',
        slug: 'interest',
    }
]


const CONSTANT = {userType, VerticalTypes, transactionTypes}

export default CONSTANT