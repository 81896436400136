import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { startTransition, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import { FireToast } from '../../../context/toastContext.js';
import AxioRequests from '../../../functions/axiosRequests.js';
import ROUTES from '../../../util/routes.js';
import VALIDATION from '../../../validation/auth.js';
import Loader from '../../loader.js';

const LendingPartnerForm = ({ id, handleConfirmation, showDetails, editingSelf = false }) => {
    const [loading, setLoading] = useState(true);
    const [defaultValues, setDefaultValues] = useState({
        reqUserID: id,
        name: '', 
        mobile: '',
        isEdit: id !== '',
    })

    useEffect(() => {
        if (loading && id !== '') handleGetRequest()
    }, [loading])

    // New Routes for Backend
    async function handleGetRequest() {
        const response = await AxioRequests.HandleGetRequest(`${ROUTES.lendingCommonPartnerRoute}/${id}`);
        if (response.status === 200) {
            startTransition(() => {
                setLoading(false)
                setDefaultValues(prev => ({ ...prev, ...response.data }))
            })

        }
    }

    if (loading && id !== '') {
        return <Loader message='Loading..' />;
    }

    return (
        <Formik
            initialValues={defaultValues}
            enableReinitialize
            validationSchema={VALIDATION.partnerFormValidation}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const response = await AxioRequests.HandlePostRequest({
                        route: ROUTES.lendingCommonPartnerRoute, // Define your registration route
                        type: id === '' ? 'post' : 'put',
                        data: values,
                        toastDescription: `Partner ${id === '' ? 'has registered' : 'profile has updated'} successfully`
                    });
                    if (response?.status === 200) {
                        handleConfirmation();
                    }
                } catch (err) {
                    console.log(err);
                    FireToast({ title: 'Error', subTitle: err.response?.data?.message || 'Registration failed' });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, touched, dirty, isValid, values, setFieldValue }) => {
                console.log(errors)
                return (
                    <Form className='row rounded border shadow-lg p-4 m-2'>
                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="name">Name</FormLabel>
                            <Field
                                name="name"
                                type="text"
                                placeholder='John Doe'
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.name && touched.name)}
                            />
                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </FormGroup>

                        <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="mobile">Mobile</FormLabel>
                            <Field
                                name="mobile"
                                type="number"
                                placeholder="9854XXXXXX"
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.mobile && touched.mobile)}
                            />
                            <ErrorMessage name="mobile" component="div" className="invalid-feedback" />
                        </FormGroup>

                      {/* <FormGroup className='col-12 col-md-6'>
                            <FormLabel htmlFor="email">Email address</FormLabel>
                            <Field
                                name="email"
                                type="email"
                                placeholder="user@example.com"
                                as={FormControl}
                                disabled={showDetails}
                                isInvalid={!!(errors.email && touched.email)}
                            />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </FormGroup> */}

                        {!showDetails &&
                            <div className='col-12 my-2 my-md-4'>
                                <Button type="submit" variant="primary" className='mx-auto d-block' disabled={!(dirty && isValid)}>
                                    {id === '' ? 'Register Partner' : 'Update Details'}
                                </Button>
                            </div>
                        }
                    </Form>
                )
            }}
        </Formik>
    );
};

export default LendingPartnerForm;
